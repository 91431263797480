import firebase from 'firebase';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthenContext } from '../../../component/AuthenProvider';
import ModalDelete from '../../../utils/ModalDelete';

export default function TopicBangGiang() {

    const [topics, setTopics] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false);
    const LIMIT = 50;
    const [show, setShow] = useState(false);
    const [data, setData] = useState()
    const closeShow = () => setShow(false)
    useEffect(() => {
        search(false)
    }, [])

    const search = async (loadMore) => {
        setLoading(true)
        try {
            let filter = firebase.firestore()
                .collection("Topic")
                .where("tags", "array-contains", "Học Băng Giảng")
                .orderBy("createdDate", 'desc')
                .limit(LIMIT)

            if (loadMore && topics.length > 0) {
                filter = filter.startAfter(topics[topics.length - 1].createdDate)
            }
            let querySnapshot = await filter.get()
            let resp = []
            querySnapshot.forEach(doc => {
                resp.push({ ...doc.data(), id: doc.id })
            });
            if (loadMore) {
                setTopics([...topics, ...resp])
            } else {
                setTopics(resp)
            }
            setHasMore(resp.length === LIMIT)
        } catch { }

        setLoading(false)
    }

    const deleteTopic = async (doc) => {
        if (show) {
            try {
                await firebase.firestore().collection("Topic").doc(doc.id).delete()
                search(false)
            } catch { }
        } else {
            setShow(true)
            setData(doc)
        }
    }
    let history = useHistory()
    return (
        <>
            <h1 className="h3 mb-2 text-gray-800">Diễn đàn</h1>
            <p className="mb-4">Các chủ đề bài viết</p>

            <div className="card shadow mb-4">
                <div className="card-header py-3 ">
                    <div className="m-0 font-weight-bold text-primary float-right"
                        onClick={() => {
                            history.push({
                                pathname: "/admin/topic/add",
                                state: "Học Băng Giảng"
                            })
                        }}
                    >
                        + Thêm mới
                    </div>
                </div>
                <ModalDelete show={show} closeShow={closeShow} deleteItem={deleteTopic} data={data} />

                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-hover" >
                            <thead>
                                <tr>
                                    <th >STT</th>
                                    <th >Nội dung</th>
                                    <th >Người tạo</th>
                                    <th >Ghim bài</th>
                                    <th >Ngày đăng</th>
                                    <th  >Cài đặt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {topics?.map((item, index) => {
                                    return (
                                        <Item topic={item} index={index + 1} deleteTopic={deleteTopic} key={item.id} />
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="5" > {hasMore && <a style={{ cursor: 'pointer', color: '#1c3f94' }} onClick={() => { search(true) }}> Load More....</a>}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

function Item({ topic, index, deleteTopic }) {
    let history = useHistory();

    return (
        <tr >
            <td className="verticalTd" >{index}</td>
            <td >
                <span dangerouslySetInnerHTML={{ __html: topic?.content }} className="content__topic" ></span>
            </td>
            <td  >
                <img alt="" src={topic?.user.photoURL} height="30" /> {topic?.user?.displayName}j
            </td>
            <td >{topic?.pintop == 1 ? "Có" : "Không" }</td>
            <td  >{topic?.createdDate.toDate().toLocaleString('vi')}</td>

            <td className="btn__setting textAlign: 'center'">
                <button style={{ backgroundColor: 'transparent' }}
                    onClick={() => {
                        history.push({
                            pathname: '/admin/topic/detail',
                            state: topic
                        })
                    }}
                >
                    <svg className="sizeSvg" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,11A1,1 0 0,1 13,12A1,1 0 0,1 12,13A1,1 0 0,1 11,12A1,1 0 0,1 12,11M12,8C14.63,8 17,9.57 18,12C16.62,15.31 12.81,16.88 9.5,15.5C7.92,14.84 6.66,13.58 6,12C7,9.57 9.37,8 12,8M12,9.5A2.5,2.5 0 0,0 9.5,12A2.5,2.5 0 0,0 12,14.5A2.5,2.5 0 0,0 14.5,12A2.5,2.5 0 0,0 12,9.5" />
                    </svg>
                </button>
                <button onClick={() => { deleteTopic(topic) }} style={{ backgroundColor: 'transparent' }}>
                    <svg className="sizeSvg" viewBox="0 0 24 24" color="#65676b">
                        <path fill="currentColor" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
                    </svg>
                </button>
                <button
                    onClick={() => {
                        history.push({
                            pathname: '/admin/topic/edit',
                            state: topic
                        })
                    }} style={{ backgroundColor: 'transparent' }}
                >
                    <svg className="sizeSvg" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z" />
                    </svg>
                </button>            </td>
        </tr>
    )
}

