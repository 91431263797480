import axios from 'axios';
import firebase from 'firebase';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthenContext } from '../../../component/AuthenProvider';
import '../../../css/add.scss';
import { Controller, useForm } from "react-hook-form";

export default function EditUser() {
    let location = useLocation();
    const user = location.state;
    let history = useHistory();
    const ALL_TAGS = ["Học Băng Giảng", "Học Tuần Báo", "Chung Thiền", "Admin"];
    const [tags, setTags] = useState(user.tags || []);
    let userCtx = useContext(AuthenContext)
    const { control, reset, handleSubmit, formState: { errors } } = useForm();
    let handleTopic = async (form) => {
        try {
            await firebase.firestore()
                .collection("User")
                .doc(user.id)
                .update({
                    tags: tags,
                    displayName: form.displayName
                })

            let obj = {
                uid: user.id,
                tags
            }

            let token = await userCtx.user.getIdToken();

            const headers = {
                'Authorization': `Bearer ${token}`,
            };

            await axios.post('https://asia-northeast1-vovinet-80452.cloudfunctions.net/subcribeAndUnsubcribe', obj, { headers })
            history.goBack()
        } catch (error) {
            console.log(error)
        }
    }

    let handleCheck = (value) => {
        try {
            if (tags.filter(item => item == value).length > 0) {
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="wrapper__add">
            <form>
                <div className="form-group">
                    <label >Tên người dùng </label>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <input
                                onBlur={onBlur}
                                onChange={e => onChange(e.target.value)}
                                value={value}

                                className="form-control"
                            />
                        )}
                        name="displayName"
                        defaultValue={user?.displayName}
                        rules={{ required: true }}
                    />
                </div>
                <div className="wrapper__add--head">
                    <label>Sửa vai trò</label>
                </div>
                <div className="wrapper__add--content">
                    <div className="wrapper__content">
                        <form>
                            <div className="wrapper__content--item">
                                <label>Nhóm:</label>
                                <div className="wrapper__content--item--tags">
                                    {ALL_TAGS.map((tag, index) => {
                                        return (
                                            <div style={{ alignItems: 'center', flex: 1, flexDirection: 'row', display: 'flex' }} key={index}>
                                                <input
                                                    type="checkbox"
                                                    value={handleCheck(tag)}
                                                    onChange={() => {
                                                        if (tags.includes(tag))
                                                            setTags(tags.filter(e => e !== tag))
                                                        else
                                                            setTags([...tags, tag])
                                                    }}
                                                    checked={tags.includes(tag)}
                                                />
                                                <div style={{ color: "#333" }}>&nbsp; {tag}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </form>
            <div className="wrapper__add--button">
                <div className="wrap__button">
                    <button onClick={() => {
                        history.goBack()
                    }}>
                        Quay lại
                    </button>
                    <button onClick={handleSubmit(handleTopic)} >
                        Xác nhận
                    </button>
                </div>
            </div>
        </div>
    )
}

