import { useContext } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route, Switch
} from "react-router-dom";
import { ToastProvider } from 'react-toast-notifications';
import AuthenProvider, { AuthenContext } from './component/AuthenProvider';
import Footer from './component/Footer';
import Header from './component/Header';
import LeftSidebar from './component/LeftSidebar';
import BangGiang from "./screen/admin/banggiang/BangGiang";
import SachBangGiang from "./screen/admin/banggiang/SachBangGiang";
import TopicBangGiang from "./screen/admin/banggiang/TopicBangGiang";
import VideoBangGiang from "./screen/admin/banggiang/VideoBangGiang";
import AddBook from "./screen/admin/book/AddBook";
import DetailBook from './screen/admin/book/DetailBook';
import EditBook from "./screen/admin/book/EditBook";
import ChungThien from "./screen/admin/chungthien/ChungThien";
import TopicChungThien from "./screen/admin/chungthien/TopicChungThien";
import AddChuyenMucKhac from "./screen/admin/chuyenmuckhac/AddChuyenMucKhac";
import DetailChuyenMucKhac from "./screen/admin/chuyenmuckhac/DetailChuyenMucKhac";
import EditChuyenMucKhac from "./screen/admin/chuyenmuckhac/EditChuyenMucKhac";
import Dashboard from './screen/admin/Dashboard';
import AddEvent from "./screen/admin/event/AddEvent";
import DetailEvent from "./screen/admin/event/DetailEvent";
import EditEvent from "./screen/admin/event/EditEvent";
import Event from "./screen/admin/event/Event";
import FileManager from "./screen/admin/file/FileManager";
import AddKinhSach from "./screen/admin/kinhsach/AddKinhSach";
import DetailKinhSach from "./screen/admin/kinhsach/DetailKinhSach";
import EditKinhSach from "./screen/admin/kinhsach/EditKinhSach";
import AddLuuTru from "./screen/admin/luutru/AddLuutru";
import EditLuuTru from "./screen/admin/luutru/EditLuutru";
import LuuTru from "./screen/admin/luutru/Luutru";
import AddMeeting from "./screen/admin/meeting/AddMeeting";
import EditMeeting from "./screen/admin/meeting/EditMeeting";
import Meeting from "./screen/admin/meeting/Meeting";
import AddNotification from './screen/admin/notification/AddNotification';
import EditNotification from './screen/admin/notification/EditNotification';
import Notification from './screen/admin/notification/Notification';
import AddPPCP from "./screen/admin/ppcp/AddPPCP";
import EditPPCP from "./screen/admin/ppcp/EditPPCP";
import AddVideoSuoiDaoVoVi from "./screen/admin/suoidaovovi/AddVideoSuoiDaoVoVi";
import ChuyenMucKhac from "./screen/admin/tailieu/ChuyenMucKhac";
import KinhSach from "./screen/admin/tailieu/KinhSach";
import PPCP from "./screen/admin/tailieu/PPCP";
import RadioTaiLieu from "./screen/admin/tailieu/Radio";
import SuoiDaoVoVi from "./screen/admin/tailieu/SuoiDaoVoVi";
import TaiLieu from "./screen/admin/tailieu/TaiLieu";
import TaiLieuBangGiang from "./screen/admin/tailieu/TaiLieuBangGiang";
import AddVideoTaiLieuBangGiang from "./screen/admin/tailieubanggiang/AddVideoTaiLieuBangGiang";
import EditVideoTaiLieuBangGiang from "./screen/admin/tailieubanggiang/EditVideoTaiLieuBangGiang";
import AddRadioTaiLieu from "./screen/admin/tailieuradio/AddRadioTaiLieu";
import EditRadioTaiLieu from "./screen/admin/tailieuradio/EditRadioTaiLieu";
import AddTopic from "./screen/admin/topic/AddTopic";
import DetailTopic from "./screen/admin/topic/DetailTopic";
import EditTopic from "./screen/admin/topic/EditTopic";
import SachTuanBao from "./screen/admin/tuanbao/SachTuanBao";
import TopicTuanBao from "./screen/admin/tuanbao/TopicTuanBao";
import TuanBao from "./screen/admin/tuanbao/TuanBao";
import VideoTuanBao from "./screen/admin/tuanbao/VideoTuanBao";
import EditUser from './screen/admin/user/EditUser';
import User from "./screen/admin/user/User";
import AddVideo from './screen/admin/video/AddVideo';
import EditVideo from "./screen/admin/video/EditVideo";
import { Login } from "./screen/Login";
import VideoLienQuan from "./screen/admin/video-lien-quan/VideoLienQuan";
import VideoLienQuanAdd from "./screen/admin/video-lien-quan/VideoLienQuanAdd";

function App() {
  return (
    <Router>
      <AuthenProvider>
        <ToastProvider>
          <AppRoute />
        </ToastProvider>
      </AuthenProvider>
    </Router>
  );
}

function AppRoute() {
  return <Switch>
    <Route exact path="/login">
      <Login />
    </Route>
    <Route exact path="/">
      <Admin />
    </Route>
    <Route path="/admin">
      <Admin />
    </Route>
  </Switch>
}

function Admin() {
  let userCtx = useContext(AuthenContext)

  if (userCtx.user === undefined)
    return <div>
      <p>Đang tải dữ liệu................</p>
    </div>;

  if (userCtx.user?.tags?.includes('Admin'))
    return <>
      <div id="wrapper">
        <LeftSidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            <div className="container-fluid">
              <Route path="/admin" exact component={Dashboard} />

              <Route path="/admin/tailieu" exact component={TaiLieu} />
              <Route path="/admin/tailieu/kinhsach" exact component={KinhSach} />
              <Route path="/admin/book/add" exact component={AddBook} />
              <Route path="/admin/book/detail" exact component={DetailBook} />
              <Route path="/admin/book/edit" exact component={EditBook} />
              <Route path="/admin/tailieu/banggiang" exact component={TaiLieuBangGiang} />
              <Route path="/admin/tailieu/suoidaovovi" exact component={SuoiDaoVoVi} />
              <Route path="/admin/video/add" exact component={AddVideo} />
              <Route path="/admin/tailieu/radio" exact component={RadioTaiLieu} />
              <Route path="/admin/tailieu/radio/add" exact component={AddRadioTaiLieu} />
              <Route path="/admin/tailieu/radio/edit" exact component={EditRadioTaiLieu} />
              <Route path="/admin/tailieu/chuyenmuckhac" exact component={ChuyenMucKhac} />
              <Route path="/admin/tailieu/chuyenmuckhac/add" exact component={AddChuyenMucKhac} />
              <Route path="/admin/tailieu/chuyenmuckhac/edit" exact component={EditChuyenMucKhac} />
              <Route path="/admin/tailieu/chuyenmuckhac/detail" exact component={DetailChuyenMucKhac} />

              <Route path="/admin/tuanbao" exact component={TuanBao} />
              <Route path="/admin/tuanbao/sach" exact component={SachTuanBao} />
              <Route path="/admin/tuanbao/topic" exact component={TopicTuanBao} />
              <Route path="/admin/tuanbao/video" exact component={VideoTuanBao} />

              <Route path="/admin/topic/add" exact component={AddTopic} />
              <Route path="/admin/topic/detail" exact component={DetailTopic} />
              <Route path="/admin/topic/edit" exact component={EditTopic} />

              <Route path="/admin/banggiang" exact component={BangGiang} />
              <Route path="/admin/banggiang/sach" exact component={SachBangGiang} />
              <Route path="/admin/banggiang/topic" exact component={TopicBangGiang} />
              <Route path="/admin/banggiang/video" exact component={VideoBangGiang} />

              <Route path="/admin/notifications" component={Notification} />
              <Route path="/admin/notification/add" component={AddNotification} />
              <Route path="/admin/notification/edit" component={EditNotification} />

              <Route path="/admin/users" exact component={User} />
              <Route path="/admin/user/edit" exact component={EditUser} />
              <Route path="/admin/files" exact component={FileManager} />

              <Route path="/admin/chungthien" exact component={ChungThien} />
              <Route path="/admin/chungthien/topic" exact component={TopicChungThien} />
              <Route path="/admin/chungthien/meets" exact component={Meeting} />
              <Route path="/admin/chungthien/meet/add" exact component={AddMeeting} />
              <Route path="/admin/chungthien/meet/edit" exact component={EditMeeting} />
              <Route path="/admin/chungthien/luutru" exact component={LuuTru} />
              <Route path="/admin/chungthien/luutru/add" exact component={AddLuuTru} />
              <Route path="/admin/chungthien/luutru/edit" exact component={EditLuuTru} />


              <Route path="/admin/events" exact component={Event} />
              <Route path="/admin/event/add" exact component={AddEvent} />
              <Route path="/admin/event/edit" exact component={EditEvent} />
              <Route path="/admin/event/detail" exact component={DetailEvent} />

              <Route path="/admin/tailieu/ppcp" exact component={PPCP} />
              <Route path="/admin/tailieu/ppcp/add" exact component={AddPPCP} />
              <Route path="/admin/tailieu/ppcp/edit" exact component={EditPPCP} />

              <Route path="/admin/tailieu/suoidaovovi/add" exact component={AddVideoSuoiDaoVoVi} />
              <Route path="/admin/tailieu/kinhsach/add" exact component={AddKinhSach} />
              <Route path="/admin/tailieu/tailieubanggiang/add" exact component={AddVideoTaiLieuBangGiang} />
              <Route path="/admin/tailieu/tailieubanggiang/edit" exact component={EditVideoTaiLieuBangGiang} />
              <Route path="/admin/tailieu/kinhsach/detail" exact component={DetailKinhSach} />
              <Route path="/admin/tailieu/kinhsach/edit" exact component={EditKinhSach} />

              <Route path="/admin/video/edit" exact component={EditVideo} />
              <Route path="/admin/video/lienquan" exact component={VideoLienQuan} />
              <Route path="/admin/video/lienquan/add" exact component={VideoLienQuanAdd} />
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <a className="scroll-to-top rounded" href="#wrapper" style={{ display: "block" }}>
        <i className="fas fa-angle-up"></i>
      </a>
    </>

  return <Redirect to="/login" />
}

export default App;
