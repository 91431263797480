import React, { useState, useContext } from 'react';
import '../../../css/table.scss'
import firebase from 'firebase';
import ModalDelete from '../../../utils/ModalDelete';
import { AuthenContext } from '../../../component/AuthenProvider';

export default function SongListsBook({ songList, id }) {

    return (
        <div className="table-wrapper ">
            <table className="fl-table ">
                <thead>
                    <tr>
                        <th>STT</th>
                        <th>Tên phụ lục</th>
                        <th>Trang Số </th>
                        <th>Cài đặt</th>
                    </tr>
                </thead>
                <tbody>
                    {songList?.map((songlist, index) => {
                        return (
                            <Item key={index} index={index + 1} songlist={songlist} id={id} />
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

function Item({ songlist, index, id }) {
    let userCtx = useContext(AuthenContext)
    const [show, setShow] = useState(false);
    const closeShow = () => setShow(false)
    const deleteItem = (data) => {
        firebase.firestore()
            .collection("Book")
            .doc(id)
            .update({
                songList: firebase.firestore.FieldValue.arrayRemove(data)
            }).then(() => { })
            .catch(err => console.log(err))

    }
    return (
        <tr>
            <td>{index}</td>
            <td  >{songlist?.title}</td>
            <td >{songlist?.pageIdx}</td>
            <td>
                <button onClick={() => { setShow(true) }} type="button" >
                    <svg className="sizeSvg" viewBox="0 0 24 24" color="#65676b">
                        <path fill="currentColor" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
                    </svg>
                </button>
            </td>
            <ModalDelete show={show} closeShow={closeShow} deleteItem={deleteItem} data={songlist} />

        </tr>
    )
}