import axios from 'axios';
import firebase from 'firebase';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import '../../../css/add.scss';
import { getYoutubeVideoId } from '../../../utils/RegexUrl';


export default function AddVideoSuoiDaoVoVi() {
    let [url, setUrl] = useState("");
   
    let history = useHistory();
    const location = useLocation();
    let tag = location.state;


    let shareYoutube = async () => {
        let videoId = getYoutubeVideoId(url)
        if (videoId) {
            await addVideo(videoId)
        }
    }

    let addVideo = async (videoId) => {

        const user = firebase.auth().currentUser

        let doc = await firebase.firestore()
            .collection("Video")
            .doc(videoId)
            .get()
        if (doc.exists) {
            alert("Video đã tồn tại")
        } else {
            var config = {
                method: 'get',
                url: `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=snippet&key=AIzaSyBulorwo-d5CGLoSc30-uxtNbxK1sb07gY`,
                headers: {}
            };

            let response = await axios(config)

            let videoData = response.data.items?.[0].snippet

            await firebase.firestore()
                .collection("Video")
                .doc(videoId)
                .set({
                    user: { uid: user.uid, displayName: user.displayName, photoURL: user.photoURL },
                    title: videoData.title,
                    createdDate: firebase.firestore.Timestamp.now(),
                    thumbnailURL: videoData.thumbnails.standard?.url || videoData.thumbnails.default?.url,
                    likes: [],
                    tags: [tag],
                    comments: []
                })

            history.goBack()
        }
    }

    return (
        <div className="wrapper__add">
            <div className="wrapper__add--head">
                <label>Thêm Video</label>
            </div>
            <form>
                <div className="form-group">
                    <label >Youtube Url</label>
                    <input
                        onChange={e => setUrl(e.target.value)}
                        value={url}
                        className="form-control"
                    />
                </div>
                
            </form>
            <div className="wrapper__add--button">
                <div className="wrap__button">
                    <button onClick={() => {
                        history.goBack()
                    }}>
                        Quay lại
                    </button>
                    <button onClick={shareYoutube} className="btn btn-primary">
                        Thêm
                    </button>
                </div>
            </div>
        </div>
    )
}


