import firebase from 'firebase';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthenContext } from '../../../component/AuthenProvider';
import ModalDelete from '../../../utils/ModalDelete';

export default function User() {
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false);
    const [text, setText] = useState("");
    const LIMIT = 25;
    const ALL_TAGS = ["Học Băng Giảng", "Học Tuần Báo", "Chung Thiền", "Admin"];
    const [tags, setTags] = useState("")
    const [show, setShow] = useState(false);
    const closeShow = () => setShow(false);
    const [data, setData] = useState()

    useEffect(() => {
        search(false)
    }, [])

    useEffect(() => {
        search(false)
    }, [tags, text])

    const search = async (loadMore) => {
        setLoading(true)
        try {
            let filter = firebase.firestore()
                .collection("User")
                .orderBy("creationTime", 'desc')
                .limit(LIMIT)

            if (tags.length > 0) {
                filter = filter.where("tags", "array-contains-any", [tags])
            }

            if (text.length > 0) {
                filter = filter.where("email", "==", text)
            }

            if (loadMore && users.length > 0) {
                filter = filter.startAfter(users[users.length - 1].creationTime)
            }
            let querySnapshot = await filter.get()
            let resp = []
            querySnapshot.forEach(doc => {
                resp.push({ ...doc.data(), id: doc.id })
            });
            if (loadMore) {
                setUsers([...users, ...resp])
            } else {
                setUsers(resp)
            }
            setHasMore(resp.length === LIMIT)
        } catch (err) { console.log(err) }
        setLoading(false)
    }

    const deleteUser = async (user) => {
        if (show) {
            try {
                await firebase.firestore().collection("User").doc(user.id).delete()
                search(false)
            } catch { }
        } else {
            setShow(true)
            setData(user)
        }
    }

    return (
        <>
            <h1 className="h3 mb-2 text-gray-800">Người dùng</h1>
            <p className="mb-4">Quản lý người dùng</p>

            <div className="card shadow mb-4">
                <div className="card-header py-3 ">
                    <div>
                        <label>Vai trò</label>
                        <select onChange={e => setTags(e.target.value)} style={{ padding: 5 }} >
                            <option value={""} >Tất cả</option>
                            {ALL_TAGS.map((item, index) => {
                                return (
                                    <option key={index} value={item} >{item}</option>
                                )
                            })}
                        </select>
                        <label>Tìm kiếm</label>
                        <input placeholder="Tìm kiếm theo email" onChange={e => setText(e.target.value)} style={{ padding: 5 }} />
                    </div>
                </div>
                <ModalDelete show={show} closeShow={closeShow} deleteItem={deleteUser} data={data} />
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-hover" >
                            <thead>
                                <tr>
                                    <th  >STT</th>
                                    <th  >Email</th>
                                    <th  >Tên</th>
                                    <th  >Vai trò</th>
                                    <th  >Avatar</th>
                                    <th  >Ngày đăng</th>
                                    <th  >Cài đặt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users?.map((item, index) => {
                                    return (
                                        <Item user={item} index={index + 1} key={item.id} deleteUser={deleteUser}/>
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="7"> {hasMore && <a style={{ cursor: 'pointer', color: '#1c3f94' }} onClick={() => { search(true) }}> Load More....</a>}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

function Item({ user, index, deleteUser }) {
    let userCtx = useContext(AuthenContext)
    const [info, setInfo] = useState();
    let history = useHistory();

    useEffect(() => {
        firebase.firestore()
            .collection("User")
            .doc(user.uid)
            .collection("secure")
            .doc("info")
            .get().then(doc => {
                setInfo(doc.data())
            })
    }, [])

    return (
        <tr >
            <td  >{index}</td>
            <td  >{info?.email || user?.phoneNumber}</td>
            <td  >{user?.displayName}</td>
            <td  >{user?.tags?.toString()}</td>
            <td  >
                <img alt="" src={user?.photoURL} className="sizeImage" />
            </td>
            <td >{user?.creationTime}</td>
            <td className="btn__setting textAlign: 'center'">
                <button onClick={() => deleteUser(user)} style={{ backgroundColor: 'transparent' }}>
                    <svg className="sizeSvg" viewBox="0 0 24 24" color="#65676b">
                        <path fill="currentColor" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
                    </svg>
                </button>
                <button
                    onClick={() => {
                        history.push({
                            pathname: '/admin/user/edit',
                            state: user
                        })
                    }} style={{ backgroundColor: 'transparent' }}
                >
                    <svg className="sizeSvg" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z" />
                    </svg>
                </button>
            </td>
        </tr>
    )
}