import React from 'react';
import { useHistory } from 'react-router-dom';
export default function BangGiang() {
    let history = useHistory();
    return (
        <>
            <ul className="menu" >
                <li onClick={() => history.push('/admin/banggiang/topic')} >Diễn đàn</li>
                <li onClick={() => history.push('/admin/banggiang/sach')}>PDF</li>
                <li onClick={() => history.push('/admin/banggiang/video')}>Ghi âm</li>
            </ul>
        </>
    )
}