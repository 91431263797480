import firebase from 'firebase';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthenContext } from '../../../component/AuthenProvider';
import '../../../css/add.scss';
import { resizeFile } from '../../../utils/FileUtils';
import { convertUrlSlug } from '../../../utils/RegexUrl';
import ImageTopic from './ImageTopic';

export default function DetailTopic() {
    let userCtx = useContext(AuthenContext)

    let location = useLocation();
    const [data, setData] = useState();
    const topic = location.state;
    useEffect(() => {
        search()
    }, [topic?.id])
    useEffect(() => {
        search()
    }, [])
    const search = () => {
        firebase.firestore()
            .collection("Topic")
            .doc(topic?.id)
            .onSnapshot(doc => {
                setData({ ...doc.data(), id: doc.id })
            })
    }
    const [showImages, setShowImages] = useState(false);
    const closeShowImages = () => {
        setShowImages(false)
    }
    let history = useHistory()
    return (
        <div className="wrapper__add">
            <div className="wrapper__add--head">
                <label>Chi tiết chủ đề</label>
            </div>
            <form>
                <div className="form-group">
                    <textarea className="form-control textareaContent" rows="3"
                        value={data?.content}
                        disabled
                    />
                </div>
                <div className="form-group form-url">
                    <label >Bình luận: {data?.comments?.length}</label>
                    {data?.comments?.length > 0 && <Comments comments={data?.comments} id={data?.id} />}

                </div>
                <div className="form-group form-url">
                    <label >Số lượt likes: </label>
                    <span>{data?.likes?.length}</span>
                </div>
                <div className="wrapper__add--content">
                    <div className="wrapper__content">
                        <div className="wrapper__content--item addImage" >
                            <label>Ảnh: (Tối đa 3)</label>
                            <>{data?.images?.length < 3 && <div className="wrapper__content--item--add" >
                                <div onClick={() => setShowImages(true)}>
                                    + Thêm mới
                                </div>
                            </div>}
                            </>
                        </div>
                        {data?.images?.length > 0 &&
                            <ImageTopic images={data?.images} id={data?.id} />
                        }

                        <AddImages show={showImages} closeShow={closeShowImages} topic={topic} />

                    </div>
                </div>
                <div className="wrapper__add--button">
                    <button type="button" onClick={() => history.goBack()} className="btn btn-primary">Quay lại</button>
                </div>
            </form>
        </div>
    )
}

function AddImages({ show, closeShow, topic }) {
    const [file, setFile] = useState()

    let uploadImageFile = async () => {
        let user = firebase.auth().currentUser;
        let now = new Date()
        let time = `${now.getDate()}${now.getMonth()}${now.getFullYear()}${now.getHours()}${now.getMinutes()}`

        if (user && file) {
            var storageRef = firebase.storage().ref();
            let fileNameExt = file.name.substring(file.name.lastIndexOf("."))

            const image = await resizeFile(file, fileNameExt);

            var uploadTask = storageRef.child(`user/${topic?.tags?.toString()}/${user.uid}/${convertUrlSlug(topic.content.substring(0, 30))}-${time}${fileNameExt}`).put(image);
            uploadTask.on('state_changed',
                (snapshot) => {
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED:
                            break;
                        case firebase.storage.TaskState.RUNNING:
                            break;
                    }
                },
                (error) => {
                    console.log("Error upload image" + error)
                },
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        firebase.firestore()
                            .collection("Topic")
                            .doc(topic.id)
                            .update({
                                images: firebase.firestore.FieldValue.arrayUnion({ url: downloadURL })
                            }).then(() => {
                                closeShow()
                                setFile()
                            }).catch(err => {
                                console.log(err)
                            })
                    });
                }
            );
        } else {
            closeShow()
            setFile()
        }
    }
    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={closeShow}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Thêm ảnh
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <label >Ảnh</label>
                    <div className="form-group">
                        <input type="file" accept="image/*"
                            onChange={e => {
                                setFile(e.target.files[0])
                            }}
                        />
                    </div>
                    {file &&
                        <img id="target" src={URL.createObjectURL(file)} alt="" className="sizeImage" />
                    }
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeShow}>
                    Đóng
                </Button>
                <button type="submit" className="btn btn-primary" onClick={() => uploadImageFile()}>
                    Xác nhận
                </button>
            </Modal.Footer>
        </Modal>
    )
}

function Comments({ comments, id }) {

    return (
        <>
            <div className="table-wrapper">
                <table className="fl-table">
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th>Bình luận</th>
                            <th>Ngày đăng</th>
                        </tr>
                    </thead>
                    <tbody>
                        {comments?.map((comment, index) => {
                            return (
                                <CommentItem key={index} index={index + 1} comment={comment} id={id} />
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

function CommentItem({ comment, index, id }) {
    const [data, setData] = useState();

    useEffect(() => {
        searchComment()
    }, [comment])

    const searchComment = async () => {
        firebase.firestore()
            .collection("Comment")
            .doc(comment.toString())
            .get().then(doc => {
                setData({ ...doc.data(), id: doc.id })
            })
    }

    return (
        <tr>
            <td>{index}</td>
            <td dangerouslySetInnerHTML={{ __html: data?.content }}></td>
            <td>{data?.createdDate.toDate().toLocaleString('vi')}</td>
        </tr>
    )
}
