import firebase from 'firebase';
import React, { useEffect, useState } from 'react';

export const AuthenContext = React.createContext({ user: undefined, setUser: () => { } })

export default function AuthenProvider(props) {
    let [user, setUser] = useState(undefined)

    const getData = async () => {
        firebase.auth().onAuthStateChanged(async (u) => {
            if (u) {
                let doc = await firebase.firestore()
                    .collection("User")
                    .doc(u.uid).get()
                let { tags } = doc.data()

                u.tags = tags

                setUser(u)
            } else {
                setUser(null)
            }
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return <AuthenContext.Provider value={{ user, setUser }}>
        {props.children}
    </AuthenContext.Provider>
}