import axios from 'axios';
import firebase from 'firebase';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useHistory, useLocation } from 'react-router-dom';
import { AuthenContext } from '../../../component/AuthenProvider';
import '../../../css/add.scss';
import { resizeFile } from '../../../utils/FileUtils';
import { convertUrlSlug } from '../../../utils/RegexUrl';

export default function AddEvent() {
    const { control, reset, handleSubmit, formState: { errors } } = useForm();
    const [file, setFile] = useState()
    let history = useHistory();
    const [pintop, setPinTop] = useState(0);
    let userCtx = useContext(AuthenContext)
    const ALL_SUB_TAGS = ["Đại Hội", "Khóa Sống Chung", "Phóng Sự", "Khác"];
    const [subtags, setSubTags] = useState("Đại Hội");
    const location = useLocation();
    let tag = location.state;

    const handleToppic = (form) => {
        try {
            if (file) {
                uploadImageFile(file, form)
            } else {
                addTopic(form)
            }
        } catch (error) {
            console.log(error)
        }
    }

    let addTopic = async (form, imageUri) => {
        let user = firebase.auth().currentUser
        try {
            await firebase.firestore().collection("Event")
                .add({
                    title: form?.title,
                    photoURL: imageUri ? imageUri : null,
                    url: form?.url,
                    user: {
                        uid: user.uid,
                        displayName: user.displayName,
                        photoURL: user.photoURL,
                    },
                    subtags,
                    tags: [tag],
                    createdDate: firebase.firestore.Timestamp.now(),
                    pintop,
                })

            history.goBack()
        } catch (error) {
            console.log(error)
        }
    }

    let uploadImageFile = async (fileImage, form) => {
        let now = new Date()
        let time = `${now.getDate()}${now.getMonth()}${now.getFullYear()}${now.getHours()}${now.getMinutes()}`

        var storageRef = firebase.storage().ref();
        let fileNameExt = fileImage.name.substring(fileImage.name.lastIndexOf("."))

        const image = await resizeFile(fileImage, fileNameExt, 1200);

        var uploadTask = storageRef.child(`Event/${convertUrlSlug(form.title)}-${time}${fileNameExt}`).put(image);

        uploadTask.on('state_changed',
            (snapshot) => {
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED:
                        break;
                    case firebase.storage.TaskState.RUNNING:
                        break;
                }
            },
            (error) => {
                console.log("Error upload image" + error)
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    addTopic(form, downloadURL)
                });
            }
        );
    }

    

    return (
        <div className="wrapper__add">
            <div className="wrapper__add--head">
                <label>Thêm sự kiện</label>
            </div>
            <form>
                <div className="form-group">
                    <label >Tiêu đề </label>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <input
                                onBlur={onBlur}
                                onChange={e => onChange(e.target.value)}
                                value={value}

                                className="form-control"
                            />
                        )}
                        name="title"
                        defaultValue=""
                        rules={{ required: true }}
                    />
                </div>
                <div className="form-group">
                    <label >Link sự kiện</label>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <input
                                onBlur={onBlur}
                                onChange={e => onChange(e.target.value)}
                                value={value}

                                className="form-control"
                            />
                        )}
                        name="url"
                        defaultValue=""
                        rules={{ required: true }}
                    />
                </div>

                <div className="wrapper__content--item">
                    <label>Nhóm:</label>
                    <div className="wrapper__content--item--tags">
                        {ALL_SUB_TAGS.map((tag, index) => {
                            return (
                                <div style={{ alignItems: 'center', flex: 1, flexDirection: 'row', display: 'flex' }} key={index}>
                                    <label style={{ width: '100%' }}>
                                        <input
                                            type="radio"
                                            onClick={() => {
                                                setSubTags(tag)
                                            }}
                                            checked={subtags.includes(tag) ? true : false}
                                        />
                                        <span style={{ color: "#333" }}>&nbsp; {tag}</span>
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <label >Ảnh</label>
                <div className="form-group">
                    <input type="file" accept="image/*"
                        onChange={e => {
                            setFile(e.target.files[0])
                        }}
                    />
                </div>
                {file &&
                    <img id="target" src={URL.createObjectURL(file)} alt="" className="sizeImage" />
                }
            </form>
            <div className="wrapper__content">
                <div className="wrapper__content--item">
                    <label >Ghim bài:</label>
                    <div className="wrapper__content--item--tags">
                        <div style={{ alignItems: 'center', flex: 1, flexDirection: 'row', display: 'flex' }}>
                            <input
                                type="radio"
                                name="pin_top"
                                value={0}
                                onClick={() => setPinTop(0)}
                                checked={pintop == 0 ? true : false}
                            />
                            <div style={{ color: "#333" }}>Không</div>
                        </div>
                        <div style={{ alignItems: 'center', flex: 1, flexDirection: 'row', display: 'flex' }} >
                            <input
                                type="radio"
                                name="pin_top"
                                value={0}
                                onClick={() => setPinTop(1)}
                                checked={pintop == 1 ? true : false}
                            />
                            <div style={{ color: "#333" }}>Có</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper__add--button">
                <div className="wrap__button">
                    <button onClick={() => {
                        history.goBack()
                    }}>
                        Quay lại
                    </button>
                    <button onClick={handleSubmit(handleToppic)} className="btn btn-primary">
                        Thêm
                    </button>
                </div>
            </div>
        </div>
    )
}

