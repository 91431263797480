import React from 'react';
import { useHistory } from 'react-router-dom';
export default function TuanBao() {
    let history = useHistory();
    return (
        <>
            <ul className="menu">
                <li onClick={() => history.push('/admin/tuanbao/topic')} >Diễn đàn</li>
                <li onClick={() => history.push('/admin/tuanbao/sach')}>PDF</li>
                <li onClick={() => history.push('/admin/tuanbao/video')}>Ghi âm</li>
            </ul>
        </>
    )
}