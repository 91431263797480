import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import '../../../css/add.scss';

export default function DetailEvent() {
    let history = useHistory();
    const location = useLocation()
    const event = location.state;

    return (
        <div className="wrapper__add">
            <div className="wrapper__add--head">
                <label>Chi tiết sự kiện</label>
            </div>
            <form>
                <div className="form-group">
                    <label >Tiêu đề </label>
                    <input
                        value={event?.title}
                        disabled
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label >Link sự kiện  <a target="_blank" href={event.url} ><svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
                        <path fill="currentColor" d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z" />
                    </svg></a> </label>
                    <input
                        value={event?.url}
                        disabled
                        className="form-control"
                    />
                </div>
                <div>
                    <label >Nhóm: </label>
                    <span>{event?.tags.toString()}</span>
                </div>

                <label >Ảnh</label>
                <img id="target" src={event?.photoURL} alt="" className="sizeImage" />

            </form>
            <div className="wrapper__add--button">
                <div className="wrap__button">
                    <button onClick={() => {
                        history.goBack()
                    }}>
                        Quay lại
                    </button>
                </div>
            </div>
        </div>
    )
}

