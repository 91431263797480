import React from 'react';
import { useHistory } from 'react-router-dom';
export default function TaiLieu() {
    let history = useHistory();
    return (
        <>
            <ul className="menu">
                <li onClick={() => history.push('/admin/tailieu/kinhsach')} >Kinh Sách</li>
                <li onClick={() => history.push('/admin/tailieu/banggiang')}>Audio/Video</li>
                <li onClick={() => history.push('/admin/tailieu/suoidaovovi')}>Suối Đạo Vô Vi</li>
                <li onClick={() => history.push('/admin/tailieu/ppcp')}>PPCP</li>
                <li onClick={() => history.push('/admin/tailieu/radio')}>Radio</li>
                <li onClick={() => history.push('/admin/tailieu/chuyenmuckhac')}>Chuyên Mục Khác</li>
            </ul>
        </>
    )
}