import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap';
export default function ModalSuccess({ show, closeShow, title }) {
    return (
        <>
            <Modal
                show={show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                
                onHide={closeShow}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button type="submit" className="btn btn-primary" onClick={closeShow}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}