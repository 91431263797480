import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthenContext } from "./AuthenProvider";

export default function LeftSidebar() {
    let userCtx = useContext(AuthenContext)

    return <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
        <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
            <div className="sidebar-brand-icon">
                <img className="rounded-circle" width="30" src="/logo192.jpg" alt="" />
            </div>
            <div className="sidebar-brand-text mx-3">VoVi Net</div>
        </Link>

        <hr className="sidebar-divider" />
        <div className="sidebar-heading">
            Interface
        </div>

        <li className="nav-item">
            <Link className="nav-link collapsed" to="/admin/users" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="true" aria-controls="collapseTwo">
                <i className="fas fa-fw fa-book"></i>
                <span>Người dùng</span>
            </Link>
            <Link className="nav-link collapsed" to="/admin/tailieu" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="true" aria-controls="collapseTwo">
                <i className="fas fa-fw fa-book"></i>
                <span>Tài Liệu</span>
            </Link>
            <Link className="nav-link collapsed" to="/admin/tuanbao" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="true" aria-controls="collapseTwo">
                <i className="fab fa-forumbee"></i>
                <span>Học Tuần Báo</span>
            </Link>
            <Link className="nav-link collapsed" to="/admin/banggiang" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="true" aria-controls="collapseTwo">
                <i className="fab fa-forumbee"></i>
                <span>Học Băng Giảng</span>
            </Link>
            <Link className="nav-link collapsed" to="/admin/chungthien" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="true" aria-controls="collapseTwo">
                <i className="fab fa-forumbee"></i>
                <span>Chung Thiền</span>
            </Link>
            <Link className="nav-link collapsed" to="/admin/events" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="true" aria-controls="collapseTwo">
                <i className="fab fa-forumbee"></i>
                <span>Sự kiện</span>
            </Link>
            <Link className="nav-link collapsed" to="/admin/notifications" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="true" aria-controls="collapseTwo">
                <i className="fab fa-forumbee"></i>
                <span>Thông báo</span>
            </Link>
            <Link className="nav-link collapsed" to="/admin/files" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="true" aria-controls="collapseTwo">
                <i className="fab fa-forumbee"></i>
                <span>File</span>
            </Link>
        </li>
    </ul>
}