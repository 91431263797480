import firebase from 'firebase';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ModalSuccess from '../../../utils/ModalSuccess';

export default function VideoLienQuanAdd(props) {
    let location = useLocation();
    const video = location.state;

    const [videos, setVideos] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false);
    const LIMIT = 50
    const [show, setShow] = useState(false);
    const closeShow = () => {
        setShow(false);
        setSearchText('');
        setVideos([]);
    }

    const algoliasearch = require('algoliasearch');
    const client = algoliasearch("ZKBCAY4L89", "6ba3a1ef548200f8ed1106443ca53053");
    const index = client.initIndex("Video");
    const [searchText, setSearchText] = useState("");
    const [titleAlert, setTitleAlert] = useState("");

    useEffect(() => {
        search(false)
    }, []);

    const search = async (loadMore) => {
        setLoading(true)
        try {
            if (searchText.trim().length > 0) {
                let searchObj = {
                    filters: "tags:'" + video.tags[0] + "'",
                    hitsPerPage: LIMIT,
                    page: 0
                }

                if (loadMore && videos.length > 0) {
                    searchObj.page = videos[videos.length - 1].page
                }

                let { hits } = await index.search(searchText, searchObj)

                let resp = []
                hits.forEach(doc => {
                    resp.push({ ...doc, id: doc.objectID, page: (searchObj.page + 1)})
                });

                if (resp.length === 0) {
                    setTitleAlert('Không tìm thấy video');
                    setShow(true);
                }

                if (loadMore) {
                    setVideos([...videos, ...resp])
                } else {
                    setVideos(resp)
                }
                setHasMore(resp.length === LIMIT)
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    const addVideoLienquan = useCallback((videoLienQuan) => {
        return () => {
            try {
                if (video.videosLienQuan.filter(item => item.uid === videoLienQuan.uid).length > 0) {
                    setTitleAlert('Video liên quan đã tồn tại');
                    setShow(true);
                } else {
                    setTitleAlert('Thêm video liên quan thành công');
                    let updateObj = {
                        videosLienQuan: [...video.videosLienQuan, videoLienQuan]
                    }
                    firebase.firestore()
                        .collection("Video")
                        .doc(video.id)
                        .update(updateObj).then(() => {
                            setShow(true);
                        });
                }
            } catch (error) {
                console.log(error)
            }
        }
    }, [video]);

    return (
        <>
            <div className="wrapper__add--head">
                <label>Thêm video liên quan {video.tags[0]}</label>
            </div>

            <div className="card shadow mb-4">
                <div className="card-header py-3 wrap-header">
                    <div className="m-0 font-weight-bold text-primary float-left search__input" >
                        <input value={searchText} onChange={e => setSearchText(e.target.value)} placeholder='Tìm kiếm' />
                        <div style={{ cursor: 'pointer' }} onClick={() => search(false)} ><svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
                            <path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                        </svg></div>
                    </div>
                </div>
                <ModalSuccess show={show} closeShow={closeShow} title={titleAlert} />
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-hover" >
                            <thead>
                                <tr>
                                    <th >STT</th>
                                    <th >Tiêu đề</th>
                                    <th >Sub tags</th>
                                    <th >Video</th>
                                    <th >Ngày đăng</th>
                                    <th >Thêm video</th>
                                </tr>
                            </thead>
                            <tbody>
                                {videos?.map((item, index) => {
                                    return (
                                        <Item video={item} index={index + 1} addVideoLienquan={addVideoLienquan} key={item.id} />
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="5" > {hasMore && <a style={{ cursor: 'pointer', color: '#1c3f94' }} onClick={() => { search(true) }}> Load More....</a>}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

function Item({ video, index, addVideoLienquan }) {
    return (
        <tr>
            <td className="verticalTd" >{index}</td>
            <td >
                {video?.title}
            </td>
            <td>{video?.subtags?.toString()}</td>
            <td  >
                <a target="_blank" href={`https://youtu.be/${video.id}`}><img src={video?.thumbnailURL} className="sizeImage" /></a>
            </td>   
            <td  >{typeof (video?.createdDate) == "object" ? video?.createdDate.toDate().toLocaleString('vi') : moment(video?.createdDate).format("HH:MM DD/MM/YYYY")}</td>

            <td className="btn__setting textAlign: 'center'">
                <button onClick={addVideoLienquan(video)} style={{ backgroundColor: 'transparent' }}>
                    <svg width="24px" height="24px" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg" aria-labelledby="plusIconTitle" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="#000000"> <title id="plusIconTitle">Plus</title> <path d="M20 12L4 12M12 4L12 20"/> </svg>
                </button>
            </td>
        </tr>
    )
}