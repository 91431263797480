import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useHistory, useLocation } from 'react-router-dom';
import '../../../css/add.scss';
import { resizeFile } from '../../../utils/FileUtils';
import { convertUrlSlug } from '../../../utils/RegexUrl';

export default function AddMeeting() {
    const { control, reset, handleSubmit, formState: { errors } } = useForm();
    const [file, setFile] = useState()
    let history = useHistory();
    const location = useLocation();
    let tag = location.state;

    const handleToppic = (form) => {
      try {
          if (file) {
              uploadImageFile(file, form)
          } else {
              addTopic(form)
          }
      } catch (error) {
          console.log(error)
      }
  }

    let addTopic = async (form, imageUri) => {
        try {
          let image = {};
            if (imageUri) {
              image = { url: imageUri };
            }
            await firebase.firestore()
              .collection("Meeting")
              .add({
                  title: form.title,
                  ID: form.ID,
                  Password: form.Password,
                  createdDate: firebase.firestore.Timestamp.now(),
                  image,
              })
            history.goBack()
        } catch (error) {
            console.log(error)
        }
    }

    let uploadImageFile = async (fileImage, form) => {
      let now = new Date()
      let time = `${now.getDate()}${now.getMonth()}${now.getFullYear()}${now.getHours()}${now.getMinutes()}`

      var storageRef = firebase.storage().ref();
      let fileNameExt = fileImage.name.substring(fileImage.name.lastIndexOf("."))

      const image = await resizeFile(fileImage, fileNameExt);

      var uploadTask = storageRef.child(`${tag}/${convertUrlSlug(form.ID.substring(0, 30))}-${time}${fileNameExt}`).put(image);

      uploadTask.on('state_changed',
          (snapshot) => {
              switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED:
                      break;
                  case firebase.storage.TaskState.RUNNING:
                      break;
              }
          },
          (error) => {
              console.log("Error upload image" + error)
          },
          () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                  addTopic(form, downloadURL)
              });
          }
      );
  }

    return (
        <div className="wrapper__add">
            <div className="wrapper__add--head">
                <label>Thêm Meeting</label>
            </div>
            <form>
            <div className="form-group">
                    <label >Tiêu đề</label>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <input
                                onBlur={onBlur}
                                onChange={e => onChange(e.target.value)}
                                value={value}

                                className="form-control"
                            />
                        )}
                        name="title"
                        rules={{ required: true }}
                    />
                </div>
                <div className="form-group">
                    <label >ID</label>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <input
                                onBlur={onBlur}
                                onChange={e => onChange(e.target.value)}
                                value={value}

                                className="form-control"
                            />
                        )}
                        name="ID"
                        rules={{ required: true }}
                    />
                </div>
                <div className="form-group">
                    <label >Password</label>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <input
                                onBlur={onBlur}
                                onChange={e => onChange(e.target.value)}
                                value={value}
                                className="form-control"
                            />
                        )}
                        name="Password"
                        rules={{ required: true }}
                    />
                </div>
                <label >Ảnh</label>
                <div className="form-group">
                    <input type="file" accept="image/*"
                        onChange={e => {
                            setFile(e.target.files[0])
                        }}
                    />
                </div>
                {file &&
                    <img id="target" src={URL.createObjectURL(file)} alt="" className="sizeImage" />
                }
            </form>
            <div className="wrapper__add--button">
                <div className="wrap__button">
                    <button onClick={() => {
                        history.goBack()
                    }}>
                        Quay lại
                    </button>
                    <button onClick={handleSubmit(handleToppic)} className="btn btn-primary">
                        Thêm
                    </button>
                </div>
            </div>
        </div>
    )
}

