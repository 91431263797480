import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import ModalDelete from '../../../utils/ModalDelete';

export default function Notification() {
    const [notis, setNotis] = useState([])
    const [hasMore, setHasMore] = useState(false);
    const LIMIT = 50;
    const [show, setShow] = useState(false);
    const [data, setData] = useState()
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)


    const closeShow = () => setShow(false)

    useEffect(() => {
        search(false)
    }, [])

    const search = async (loadMore) => {
        try {
            let filter = firebase.firestore()
                .collection("Notification")
                .orderBy('createdDate', 'desc')
                .limit(LIMIT)

            if (fromDate) {
                filter = filter.where("createdDate", ">=", fromDate)
            }

            if (toDate) {
                filter = filter.where("createdDate", "<=", toDate)
            }

            if (loadMore && notis.length > 0) {
                filter = filter.startAfter(notis[notis.length - 1].createdDate)
            }

            let querySnapshot = await filter.get()
            let resp = []
            querySnapshot.forEach(doc => {
                resp.push({ ...doc.data(), id: doc.id })
            });
            if (loadMore) {
                setNotis([...notis, ...resp])
            } else {
                setNotis(resp)
            }
            setHasMore(resp.length === LIMIT)
        } catch (err) { console.log(err) }
    }

    const deleteNotification = async (doc) => {
        if (show) {
            try {
                await firebase.firestore().collection("Notification").doc(doc.id).delete()
                search(false)
            } catch (err) { console.log(err) }
        } else {
            setShow(true)
            setData(doc)
        }
    }

    let deleteAll = async () => {
        let filter = firebase.firestore()
            .collection("Notification")
            .orderBy('createdDate', 'desc')

        if (fromDate) {
            filter = filter.where("createdDate", ">=", fromDate)
        }

        if (toDate) {
            filter = filter.where("createdDate", "<=", toDate)
        }

        let batch = firebase.firestore().batch();

        const data = await filter.get();

        data.forEach(doc => {
            batch.delete(doc.ref);
        });
        await batch.commit();
        search()
    }

    return (
        <>
            <h1 className="h3 mb-2 text-gray-800">Thông báo</h1>
            <div className="card shadow mb-4">
                <div className="card-header py-3 wrap-header">
                    <div className="m-0 font-weight-bold text-primary float-left search__input" >
                        <input placeholder="Từ ngày" type='date' style={{ width: 200 }} onChange={e => setFromDate(new Date(e.target.value))} />
                        <input placeholder="Tới ngày" type='date' style={{ width: 200 }} onChange={e => setToDate(new Date(e.target.value))} />
                        <div style={{ cursor: 'pointer' }} onClick={() => search(false)} >
                            <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
                                <path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                            </svg></div>
                    </div>
                </div>

                <ModalDelete show={show} closeShow={closeShow} deleteItem={deleteNotification} data={data} />

                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-hover" >
                            <thead>
                                <tr>
                                    <th>
                                        <button className='btn btn-danger' onClick={deleteAll}>Xóa hết</button>
                                    </th>
                                    <th >Nhóm</th>
                                    <th >Nội dung</th>
                                    <th >Ngày đăng</th>
                                    <th >Cài đặt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {notis?.map((item, index) => {
                                    return (
                                        <Item noti={item} index={index + 1} deleteNotification={deleteNotification} key={item.id} />
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="6" > {hasMore && <a style={{ cursor: 'pointer', color: '#1c3f94' }} onClick={() => { search(true) }}> Load More....</a>}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

function Item({ noti, index, deleteNotification }) {
    return (
        <tr >
            <td className="verticalTd" >{index}</td>
            <td className="verticalTd" >{noti.tags}</td>
            <td >
                <span dangerouslySetInnerHTML={{ __html: noti?.body }} className="content__topic" ></span>
            </td>
            <td  >{noti?.createdDate.toDate().toLocaleString('vi')}</td>
            <td className="btn__setting textAlign: 'center'">
                <button onClick={() => { deleteNotification(noti) }} style={{ backgroundColor: 'transparent' }}>
                    <svg className="sizeSvg" viewBox="0 0 24 24" color="#65676b">
                        <path fill="currentColor" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
                    </svg>
                </button>
            </td>
        </tr>
    )
}