import firebase from 'firebase';
import React, { useContext } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useHistory } from 'react-router';
import { AuthenContext } from '../component/AuthenProvider';

export function Login() {
    let history = useHistory();
    let userCtx = useContext(AuthenContext)

    // Configure FirebaseUI.
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // We will display Google and Facebook as auth providers.
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: async (authResult) => {
                let user = authResult.user;
                let doc = await firebase.firestore()
                    .collection("User")
                    .doc(user.uid).get()


                userCtx.setUser(user)
                history.replace("/admin")
                return false;
            }
        },

        signInOptions: [
            // Leave the lines as is for the providers you want to offer your users.
            // {
            //     provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            //     // Invisible reCAPTCHA with image challenge and bottom left badge.
            //     recaptchaParameters: {
            //         type: 'image',
            //         size: 'invisible',
            //         badge: 'bottomleft'
            //     },
            //     defaultCountry: 'VN'
            // },
            firebase.auth.GoogleAuthProvider.PROVIDER_ID
        ],
        // Terms of service url/callback.
        tosUrl: 'https://tcrocket.com/dieu-khoan-su-dung/',
        // Privacy policy url/callback.
        privacyPolicyUrl: "https://tcrocket.com/chinh-sach-quyen-rieng-tu/"
    }

    return <div >
        <div className="container " >
            <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-6 col-md-8">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">
                                        <div >
                                            <h1 className="h4 text-gray-900 mb-4 text-center">Welcome Back!</h1>
                                        </div>
                                        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                                        <div className="text-center login-footer p-5">
                                            <p>Power By <a href="https://tcrocket.com">TCrocket.com.</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}