import firebase from 'firebase';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthenContext } from '../../../component/AuthenProvider';
import ModalDelete from '../../../utils/ModalDelete';
export default function SachBangGiang() {
    let userCtx = useContext(AuthenContext)
    const [books, setBooks] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false);
    const LIMIT = 50
    const [show, setShow] = useState(false);
    const [data, setData] = useState()
    const closeShow = () => setShow(false);
    const ALL_SUB_TAGS = ["2020", "2021", "2022", "2023", "2024", "2025"];
    const [subtag, setSubTag] = useState("");
    const algoliasearch = require('algoliasearch');
    const client = algoliasearch("ZKBCAY4L89", "6ba3a1ef548200f8ed1106443ca53053");
    const index = client.initIndex("Book");
    const [searchText, setSearchText] = useState("")
    let handleSubTags = (event, tag) => {
        if (event.target.checked) {
            setSubTag(tag)
        } else {
            setSubTag("")
        }
    }
    useEffect(() => {
        search(false)
    }, [subtag])

    const search = async (loadMore) => {
        setLoading(true)
        try {
            if (searchText.trim().length > 0) {
                let searchObj = {
                    filters: "tags:'Học Băng Giảng'",
                    hitsPerPage: LIMIT,
                    page: 0
                }

                if (subtag.trim().length > 0) {
                    searchObj.filters += ` AND subtags:'${subtag}'`
                }

                if (loadMore && books.length > 0) {
                    searchObj.page = books[books.length - 1].page
                }

                let { hits } = await index.search(searchText, searchObj)

                let resp = []
                hits.forEach(doc => {
                    resp.push({ ...doc, id: doc.objectID, page: (searchObj.page + 1) })
                });

                if (loadMore) {
                    setBooks([...books, ...resp])
                } else {
                    setBooks(resp)
                }
                setHasMore(resp.length === LIMIT)
            } else {
                let filter = firebase.firestore()
                    .collection("Book").where("tags", 'array-contains-any', ["Học Băng Giảng"])
                    .orderBy("createdDate", "desc")
                    .limit(LIMIT)

                if (subtag.trim().length > 0) {
                    filter = filter.where("subtags", "==", subtag.toString())
                }

                if (loadMore && books.length > 0) {
                    filter = filter.startAfter(books[books.length - 1].createdDate)
                }

                let querySnapshot = await filter.get()

                let resp = []
                querySnapshot.forEach(doc => {
                    resp.push({ ...doc.data(), id: doc.id })
                });

                if (loadMore) {
                    setBooks([...books, ...resp])
                } else {
                    setBooks(resp)
                }
                setHasMore(resp.length === LIMIT)
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteBook = async (doc) => {
        if (show) {
            try {
                await firebase.firestore().collection("Book").doc(doc.id).delete()
                search(false)
            } catch { }
        } else {
            setShow(true)
            setData(doc)
        }
    }
    let history = useHistory()
    return (
        <>
            <h1 className="h3 mb-2 text-gray-800">Băng Giảng</h1>
            <p className="mb-4">PDF Băng Giảng</p>

            <div className="card shadow mb-4">
                <div className='subtags' >
                    {ALL_SUB_TAGS.map((tag, index) => {
                        return (
                            <div key={index}>
                                <input type='checkbox' name='subtags' onChange={(event) => handleSubTags(event, tag)}
                                    checked={subtag == tag ? true : false}
                                /> {tag}
                            </div>
                        )
                    })}
                </div>
                <div className="card-header py-3 wrap-header">
                    <div className="m-0 font-weight-bold text-primary float-left search__input" >
                        <input value={searchText} onChange={e => setSearchText(e.target.value)} placeholder='Tìm kiếm' />
                        <div style={{ cursor: 'pointer' }} onClick={() => search(false)} ><svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
                            <path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                        </svg></div>
                    </div>
                    <div className="m-0 font-weight-bold text-primary float-right"
                        onClick={() => {
                            history.push({
                                pathname: '/admin/book/add',
                                state: "Học Băng Giảng"
                            })
                        }}
                    >
                        + Thêm mới
                    </div>
                </div>
                <ModalDelete show={show} closeShow={closeShow} deleteItem={deleteBook} />

                <div className="card-body">
                    <div className=" table-responsive">
                        <table className="table table-hover ">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Ảnh</th>
                                    <th>Tên</th>
                                    <th>Tag</th>
                                    <th>Sub tag</th>
                                    <th>Ngày đăng</th>
                                    <th>Setting</th>
                                </tr>
                            </thead>
                            <tbody>
                                {books?.map((item, index) => {
                                    return (
                                        <Item book={item} index={index + 1} deleteBook={deleteBook} key={item.id} />
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="6" > {hasMore && <a style={{ cursor: 'pointer', color: '#1c3f94' }} onClick={() => { search(true) }}> Load More....</a>}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <ModalDelete show={show} closeShow={closeShow} deleteItem={deleteBook} data={data} />
        </>
    )
}

function Item({ book, index, deleteBook }) {
    let history = useHistory();
    let userCtx = useContext(AuthenContext)

    return (
        <tr >
            <td  >{index}</td>
            <td className="align-middle "  >
                <img src={book.image} style={{ width: 60, height: 80, objectFit: 'contain' }} />
            </td>
            <td className="align-middle text-start" dangerouslySetInnerHTML={{ __html: book.name }}></td>
            <td >{book?.tags.toString()}</td>
            <td >{book?.subtags}</td>
            <td  >{typeof (book?.createdDate) == "object" ? book?.createdDate.toDate().toLocaleString('vi') : moment(book?.createdDate).format("HH:MM DD/MM/YYYY")}</td>
            <td className="btn__setting">
                <button style={{ backgroundColor: 'transparent' }}
                    onClick={() => {
                        history.push({
                            pathname: '/admin/book/detail',
                            state: book
                        })
                    }}
                >
                    <svg className="sizeSvg" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,11A1,1 0 0,1 13,12A1,1 0 0,1 12,13A1,1 0 0,1 11,12A1,1 0 0,1 12,11M12,8C14.63,8 17,9.57 18,12C16.62,15.31 12.81,16.88 9.5,15.5C7.92,14.84 6.66,13.58 6,12C7,9.57 9.37,8 12,8M12,9.5A2.5,2.5 0 0,0 9.5,12A2.5,2.5 0 0,0 12,14.5A2.5,2.5 0 0,0 14.5,12A2.5,2.5 0 0,0 12,9.5" />
                    </svg>
                </button>

                <button onClick={() => { deleteBook(book) }} style={{ backgroundColor: 'transparent' }}>
                    <svg className="sizeSvg" viewBox="0 0 24 24" color="#65676b">
                        <path fill="currentColor" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
                    </svg>
                </button>
                <button
                    onClick={() => {
                        history.push({
                            pathname: '/admin/book/edit',
                            state: book
                        })
                    }} style={{ backgroundColor: 'transparent' }}
                >
                    <svg className="sizeSvg" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z" />
                    </svg>
                </button>
            </td>
        </tr>
    )
}
