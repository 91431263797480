import firebase from 'firebase';
import React, { useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useHistory, useLocation } from 'react-router-dom';
import '../../../css/add.scss';

export default function EditTopic() {
    const { control, reset, handleSubmit, formState: { errors } } = useForm();
    let location = useLocation();
    const topic = location.state;
    let history = useHistory();
    let [pintop,setPinTop] = useState(topic?.pintop)
    let sumbitAdd = async (form) => {
        try {
            await firebase.firestore()
                .collection("Topic")
                .doc(topic?.id)
                .update({
                    content: form?.content,
                    pintop:pintop,
                })
            history.goBack()
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="wrapper__add">
            <div className="wrapper__add--head">
                <label>Sửa chủ đề</label>
            </div>
            <div className="wrapper__add--content">
                <div className="wrapper__content">
                    <form>
                        <div className="form-group">
                            <label >Nội dung</label>
                            <Controller
                                control={control}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <textarea className="form-control textareaContent" rows="3"
                                        value={value}
                                        onChange={e => onChange(e.target.value)}
                                    />
                                )}
                                name="content"
                                defaultValue={topic?.content}
                                rules={{ required: true }}
                            />
                        </div>
                    </form>
                </div>
                <div className="wrapper__content">
                    <div className="wrapper__content--item">
                        <label >Ghim bài:</label>
                        <div className="wrapper__content--item--tags">
                            <div style={{ alignItems: 'center', flex: 1, flexDirection: 'row', display: 'flex' }}>
                                <input
                                    type="radio"
                                    name="pin_top"
                                    value={0}
                                    onClick={() => setPinTop(0)}
                                    checked={pintop == 0 ? true : false}
                                />
                                <div style={{ color: "#333" }}>Không</div>
                            </div>
                            <div style={{ alignItems: 'center', flex: 1, flexDirection: 'row', display: 'flex' }} >
                                <input
                                    type="radio"
                                    name="pin_top"
                                    value={0}
                                    onClick={() => setPinTop(1)}
                                    checked={pintop == 1 ? true : false}
                                />
                                <div style={{ color: "#333" }}>Có</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper__add--button">
                <div className="wrap__button">
                    <button onClick={() => {
                        history.goBack()
                    }}>
                        Quay lại
                    </button>
                    <button onClick={handleSubmit(sumbitAdd)}>
                        Xác nhận
                    </button>
                </div>
            </div>
        </div>
    )
}

