import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap';
export default function ModalDelete({ deleteItem, show, closeShow, data }) {
    return (
        <>
            <Modal
                show={show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                
                onHide={closeShow}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Bạn chắc chắn muốn xóa
                    </Modal.Title>
                </Modal.Header>
                {/* <Modal.Body>
                    <div></div>
                </Modal.Body> */}
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeShow}>
                        Đóng
                    </Button>
                    <button type="submit" className="btn btn-primary" onClick={async () => {
                        await deleteItem(data)
                        closeShow()
                    }}>
                        Xác nhận
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}