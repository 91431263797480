import React from 'react';
import { useHistory } from 'react-router-dom';
export default function ChungThien() {
    let history = useHistory();
    return (
        <>
            <ul className="menu" >
                <li onClick={() => history.push('/admin/chungthien/topic')} >Diễn đàn</li>
                <li onClick={() => history.push('/admin/chungthien/meets')}>Zoom</li>
                <li onClick={() => history.push('/admin/chungthien/luutru')}>Lưu trữ</li>
            </ul>
        </>
    )
}