import axios from 'axios';
import firebase from 'firebase';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { AuthenContext } from '../../../component/AuthenProvider';
import '../../../css/add.scss';
import { resizeFile } from '../../../utils/FileUtils';
import { convertUrlSlug } from '../../../utils/RegexUrl';

export default function AddNotification() {
    const { control, reset, handleSubmit, formState: { errors } } = useForm();
    const [file, setFile] = useState()
    let history = useHistory();
    let userCtx = useContext(AuthenContext)

    const handleToppic = (form) => {
        try {
            // if (file) {
            //     uploadImageFile(file, form)
            // } else {
            addTopic(form)
            // }
        } catch (error) {
            console.log(error)
        }
    }

    let addTopic = async (form, imageUri) => {
        try {
            let obj = {
                title: form.title,
                content: form.content,
            }

            let token = await userCtx.user.getIdToken();

            const headers = {
                'Authorization': `Bearer ${token}`,
            };
            console.log(headers)
            let resp = await axios.post('https://asia-northeast1-vovinet-80452.cloudfunctions.net/sendNotification', obj, { headers })
            console.log(resp)

            alert("Gửi thành công!")
        } catch (error) {
            console.log(error)
        }
    }

    // let uploadImageFile = async (fileImage, form) => {
    //     let now = new Date()
    //     let time = `${now.getDate()}${now.getMonth()}${now.getFullYear()}${now.getHours()}${now.getMinutes()}`

    //     var storageRef = firebase.storage().ref();
    //     let fileNameExt = fileImage.name.substring(fileImage.name.lastIndexOf("."))

    //     const image = await resizeFile(fileImage, fileNameExt, 1200);

    //     var uploadTask = storageRef.child(`FCMImages/${convertUrlSlug(form.title)}-${time}${fileNameExt}`).put(image);

    //     uploadTask.on('state_changed',
    //         (snapshot) => {
    //             switch (snapshot.state) {
    //                 case firebase.storage.TaskState.PAUSED:
    //                     break;
    //                 case firebase.storage.TaskState.RUNNING:
    //                     break;
    //             }
    //         },
    //         (error) => {
    //             console.log("Error upload image" + error)
    //         },
    //         () => {
    //             uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
    //                 addTopic(form, downloadURL)
    //             });
    //         }
    //     );
    // }

    return (
        <div className="wrapper__add">
            <div className="wrapper__add--head">
                <label>Thêm thông báo</label>
            </div>
            <form>
                <div className="form-group">
                    <label >Tiêu đề </label>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <input
                                onBlur={onBlur}
                                onChange={e => onChange(e.target.value)}
                                value={value}

                                className="form-control"
                            />
                        )}
                        name="title"
                        defaultValue=""
                        rules={{ required: true }}
                    />
                </div>
                <div className="form-group">
                    <label >Nội dung</label>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <textarea className="form-control textareaContent" rows="1"
                                value={value}
                                onChange={e => onChange(e.target.value)}
                            />
                        )}
                        name="content"
                        defaultValue=""
                        rules={{ required: true }}
                    />
                </div>
                {/* <div className="form-group">
                    <label >Dữ liệu</label>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <textarea className="form-control textareaContent" rows="1"
                                value={value}
                                onChange={e => onChange(e.target.value)}
                            />
                        )}
                        name="data"
                        defaultValue='{"stack": {"name":"NotificationStack","screen":{"screen":"AppNotificationTab","initial":false}}}'
                    />
                </div>
                <label >Ảnh</label>
                <div className="form-group">
                    <input type="file" accept="image/*"
                        onChange={e => {
                            setFile(e.target.files[0])
                        }}
                    />
                </div>
                {file &&
                    <img id="target" src={URL.createObjectURL(file)} alt="" className="sizeImage" />
                } */}
            </form>
            <div className="wrapper__add--button">
                <div className="wrap__button">
                    {/* <button onClick={() => {
                        history.goBack()
                    }}>
                        Quay lại
                    </button> */}
                    <button onClick={handleSubmit(handleToppic)} className="btn btn-primary">
                        Thêm
                    </button>
                </div>
            </div>
        </div>
    )
}

