import Resizer from "react-image-file-resizer";

export const resizeFile = (file, fileNameExt = "jpeg", maxWidth = 700, maxHeight = 1000) => new Promise(resolve => {
    let compressFormat = "JPEG"

    if (fileNameExt.toLowerCase() === '.png') {
        compressFormat = "PNG"
    }

    if (fileNameExt.toLowerCase() === '.webp') {
        compressFormat = "WEBP"
    }

    Resizer.imageFileResizer(file, maxWidth, maxHeight, compressFormat, 80, 0,
        uri => {
            resolve(uri);
        }, 'blob');
});