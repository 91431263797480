import firebase from 'firebase';
// const firebaseConfig = {
//     apiKey: "AIzaSyCO0HnxSVHfCwvYdiPs_HLZTg_xawLr0fY",
//     authDomain: "javamaster-test.firebaseapp.com",
//     databaseURL: "https://javamaster-test.firebaseio.com",
//     projectId: "javamaster-test",
//     storageBucket: "javamaster-test.appspot.com",
//     messagingSenderId: "396629979133",
//     appId: "1:396629979133:web:61c18ea57e0b71da117394"
// };

const firebaseConfig = {
    apiKey: "AIzaSyCH4GwPOqO-1hiYJ2kNV4hYC90JTaJyXDY",
    authDomain: "vovinet-80452.firebaseapp.com",
    projectId: "vovinet-80452",
    storageBucket: "vovinet-80452.appspot.com",
    messagingSenderId: "990696670961",
    appId: "1:990696670961:web:e8753ad743166d0099cb50",
    measurementId: "G-N4Q9YT5SL0"
};
// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app(); // if already initialized, use that one
}

export const firestore = firebase.firestore();