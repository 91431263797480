import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useToasts } from 'react-toast-notifications';
import '../../../css/file.scss';
import { convertUrlSlug } from '../../../utils/RegexUrl';

export default function FileManager() {
    const { addToast } = useToasts();
    let [files, setFiles] = useState([])
    let [fullPath, setFullPath] = useState("")
    let [text, setText] = useState("")
    let [fileList, setFileList] = useState([])
    const [show, setShow] = useState(false);
    const [data, setData] = useState()
    const closeShow = () => setShow(false)
    const [showFolder, setShowFolder] = useState(false);
    const closeShowFolder = () => setShowFolder(false)

    let search = (path) => {
        setFullPath(path)
        var storage = firebase.storage();
        var storageRef = storage.ref();

        var listRef = storageRef.child(path);

        // Find all the prefixes and items.
        listRef.listAll()
            .then(async (res) => {///------/
                let respFiles = []

                res.prefixes.forEach((folderRef) => {
                    respFiles.push({
                        name: folderRef.name,
                        type: "Folder",
                        size: "-",
                        updated: "-",
                        fullPath: folderRef.fullPath
                    })
                });
                setFileList(respFiles)
                res.items.forEach((itemRef) => {
                    // All the items under listRef.
                    itemRef.getMetadata().then(meta => {
                        setFileList(files => ([...files, {
                            name: itemRef.name,
                            type: meta.contentType,
                            size: Math.round(meta.size * 100 / 1024) / 100 + ' KB',
                            updated: meta.updated,
                            fullPath: meta.fullPath
                        }]))
                    })
                });
            }).catch((error) => {
                // Uh-oh, an error occurred!
                console.log(error)
            });
    }

    const getDownloadURL = (path) => {
        var storage = firebase.storage();
        var storageRef = storage.ref();

        var itemRef = storageRef.child(path);

        itemRef.getDownloadURL().then(url => {
            copyToClipboard(url);
            addToast(`Copied the text: ${url}`, { appearance: 'success', autoDismiss: true });
        })
    }

    const copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

    };

    const viewFile = (path) => {
        var storage = firebase.storage();
        var storageRef = storage.ref();

        var itemRef = storageRef.child(path);

        itemRef.getDownloadURL().then(url => {
            window.open(url)
        })
    }

    useEffect(() => {
        search("")
    }, [])

    //khi thay doi text va search
    useEffect(() => {
        filter()
    }, [fileList, text])

    let filter = () => {
        setFiles(fileList.filter(item => item.name.toLowerCase().includes(text)))
    }

    let uploadImageFile = () => {
        var input = document.createElement('input');
        input.type = 'file';
        input.onchange = e => {
            var file = e.target.files[0];
            var storageRef = firebase.storage().ref();
            let fileNameExt = file.name.substring(file.name.lastIndexOf("."))
            let fileName = file.name.substring(0, file.name.lastIndexOf("."))

            var uploadTask = storageRef.child(`${fullPath}/${convertUrlSlug(fileName)}${fileNameExt}`).put(file);
            uploadTask.on('state_changed',
                (snapshot) => {
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED:
                            break;
                        case firebase.storage.TaskState.RUNNING:
                            break;
                    }
                },
                (error) => {
                    console.log("Error upload image" + error)
                },
                () => {
                    search(fullPath)
                }
            );
        }

        input.click();
    }

    let deleteFile = (path) => {
        if (show) {
            var storage = firebase.storage();
            var storageRef = storage.ref();

            var itemRef = storageRef.child(path);

            itemRef.delete().then(() => {
                search(fullPath)
            }).catch((error) => {
                console.log(error.code)
            });
        } else {
            setShow(true)
            setData(path)
        }
    }

    let createFolder = (folderName) => {
        var file = new Blob([""], { type: 'text/plain' });

        var storageRef = firebase.storage().ref();

        var uploadTask = storageRef.child(`${fullPath}/${folderName}/dump.txt`).put(file);
        uploadTask.on('state_changed',
            (snapshot) => {
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED:
                        break;
                    case firebase.storage.TaskState.RUNNING:
                        break;
                }
            },
            (error) => {
                console.log("Error upload image" + error)
            },
            () => {
                search(fullPath)
            }
        );
    }

    let sortBy = (field) => {
        if (field === 'updated') {
            let files = fileList.sort((a, b) => new Date(b.updated) - new Date(a.updated))
            setFiles(files)
        }
    }

    let renderFileTypeIcon = (type) => {
        return type === 'Folder' ? <svg className="classSvg" viewBox="0 0 24 24">
            <path fill="#1d6bd1" d="M20,18H4V8H20M20,6H12L10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6Z" />
        </svg>
            : (type === 'image/jpeg' || type === 'image/png' || type === 'image/jpg') ? <svg className="classSvg" viewBox="0 0 24 24">
                <path fill="#00a12b" d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
            </svg> :
                type === 'application/pdf' ? <svg className="classSvg" viewBox="0 0 24 24">
                    <path fill="#f22400" d="M12,10.5H13V13.5H12V10.5M7,11.5H8V10.5H7V11.5M20,6V18A2,2 0 0,1 18,20H6A2,2 0 0,1 4,18V6A2,2 0 0,1 6,4H18A2,2 0 0,1 20,6M9.5,10.5A1.5,1.5 0 0,0 8,9H5.5V15H7V13H8A1.5,1.5 0 0,0 9.5,11.5V10.5M14.5,10.5A1.5,1.5 0 0,0 13,9H10.5V15H13A1.5,1.5 0 0,0 14.5,13.5V10.5M18.5,9H15.5V15H17V13H18.5V11.5H17V10.5H18.5V9Z" />
                </svg>
                    : type === 'text/plain' ? <svg className="classSvg" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M5,3C3.89,3 3,3.89 3,5V19C3,20.11 3.89,21 5,21H19C20.11,21 21,20.11 21,19V5C21,3.89 20.11,3 19,3H5M5,5H19V19H5V5M7,7V9H17V7H7M7,11V13H17V11H7M7,15V17H14V15H7Z" />
                    </svg>
                        : <svg className="classSvg" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M20 6H12L10 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6M18 12H16V14H18V16H16V18H14V16H16V14H14V12H16V10H14V8H16V10H18V12Z" />
                        </svg>
    }

    return (
        <div className="file">
            <div className="container-fluid">
                <div className="head__file">
                    <div className="flexDiv">
                        <div>
                            <span style={{ cursor: 'pointer' }} onClick={() => {
                                search("")
                                setText("")
                            }}>
                                <svg className="classSvg" viewBox="0 0 24 24">
                                    <path fill="#1d6bd1" d="M12 5.69L17 10.19V18H15V12H9V18H7V10.19L12 5.69M12 3L2 12H5V20H11V14H13V20H19V12H22L12 3Z" />
                                </svg>
                            </span>
                        </div>
                        <div>

                        </div>
                        <div className="flexDiv__text">File Manager</div>
                    </div>
                    <div className="head__file--search">
                        <div className="search">
                            <input type="text" placeholder="Search..." value={text} onChange={(e) => setText(e.target.value)} />
                            <div className="search__btn">
                                <svg className="classSvg" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                                </svg>
                            </div>
                        </div>
                        <div className="flexDiv  ">
                            <div className="flexDiv eventClick" onClick={uploadImageFile}>
                                <div >
                                    <svg className="classSvg" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" />
                                    </svg>
                                </div>
                                <div className="eventClick__text">Upload</div>
                            </div>
                            &nbsp; &nbsp;
                            <div className="flexDiv eventClick" onClick={() => setShowFolder(true)}>
                                <div >
                                    <svg className="classSvg" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M10,4L12,6H20A2,2 0 0,1 22,8V18A2,2 0 0,1 20,20H4C2.89,20 2,19.1 2,18V6C2,4.89 2.89,4 4,4H10M15,9V12H12V14H15V17H17V14H20V12H17V9H15Z" />
                                    </svg>
                                </div>
                                <div className="eventClick__text">New Folder</div>
                            </div>
                        </div>
                    </div>
                </div>
                {fullPath !== "" &&
                    <div className="head__link">
                        <span style={{ cursor: 'pointer' }} onClick={() => {
                            let path = fullPath.substring(0, fullPath.lastIndexOf("/"))
                            search(path)
                        }}>
                            <div>
                                <svg className="classSvg" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
                                </svg>
                            </div></span>
                        <div className="head__link--sub">{fullPath.replaceAll("/", " > ")}</div>
                    </div>
                }
                <table className="table table-hover table-responsive-stack bg-white table-md">
                    <thead className="thead-dark">
                        <tr>
                            <th>Name</th>
                            <th>Size</th>
                            <th>Type</th>
                            <th>
                                <span style={{ cursor: 'pointer' }} onClick={() => {
                                    sortBy("updated")
                                }}>Modified</span>
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {files.map((file, index) => {
                            return <tr key={index}>
                                <td className="tdName">
                                    {renderFileTypeIcon(file.type)}
                                    {file.type === 'Folder' ? <span onClick={() => {
                                        search(file.fullPath)
                                    }}>
                                        {file.name}
                                    </span>
                                        :
                                        <span onClick={() => {
                                            getDownloadURL(file.fullPath)
                                        }}>
                                            {file.name}
                                        </span>
                                    }
                                </td>
                                <td >{file.size}</td>
                                <td>{file.type}</td>
                                <td>{new Date(file.updated).toLocaleString('vi') !== "Invalid Date" ? new Date(file.updated).toLocaleString('vi') : "-"}</td>
                                <td className="tdAction">
                                    <span title="Preview" onClick={() => {
                                        if (file.type === 'Folder')
                                            search(file.fullPath)
                                        else if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'text/plain')
                                            viewFile(file.fullPath)
                                        else
                                            getDownloadURL(file.fullPath)
                                    }}>
                                        <svg className="classSvg" viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M17,18C17.56,18 18,18.44 18,19C18,19.56 17.56,20 17,20C16.44,20 16,19.56 16,19C16,18.44 16.44,18 17,18M17,15C14.27,15 11.94,16.66 11,19C11.94,21.34 14.27,23 17,23C19.73,23 22.06,21.34 23,19C22.06,16.66 19.73,15 17,15M17,21.5A2.5,2.5 0 0,1 14.5,19A2.5,2.5 0 0,1 17,16.5A2.5,2.5 0 0,1 19.5,19A2.5,2.5 0 0,1 17,21.5M9.14,19.75L8.85,19L9.14,18.26C10.43,15.06 13.5,13 17,13C18.05,13 19.06,13.21 20,13.56V8L14,2H6C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H10.5C9.95,21.34 9.5,20.58 9.14,19.75M13,3.5L18.5,9H13V3.5Z" />
                                        </svg>
                                    </span>

                                    {file.type !== 'Folder' &&
                                        < span title="Delete" onClick={() => {
                                            deleteFile(file.fullPath)
                                        }}>
                                            <svg className="classSvg" viewBox="0 0 24 24" >
                                                <path fill="currentColor" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M9,8H11V17H9V8M13,8H15V17H13V8Z" />
                                            </svg>
                                        </span>
                                    }
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <ModalDelete show={show} closeShow={closeShow} deleteItem={deleteFile} data={data} />
            <ModalFolder show={showFolder} closeShow={closeShowFolder} createFolder={createFolder} />
        </div >

    )
}

function ModalDelete({ deleteItem, show, closeShow, data }) {
    return (
        <>
            <Modal
                show={show}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                onHide={closeShow}
            >
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div style={{ fontSize: 18, color: '#C62828' }} className="flexDiv" ><svg className="classSvg" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M13 14H11V9H13M13 18H11V16H13M1 21H23L12 2L1 21Z" />
                        </svg>
                            <div>Bạn chắc chắn muốn xóa ?</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div onClick={() => {
                        closeShow()
                    }} className="btn btn-default" >
                        Đóng
                    </div>
                    <button type="submit" onClick={async () => {
                        await deleteItem(data)
                        closeShow()
                    }}
                        className="btn btn-danger"
                    >
                        Vẫn Xóa
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function ModalFolder({ show, closeShow, createFolder }) {
    const [name, setName] = useState();

    return (
        <>
            <Modal
                show={show}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={closeShow}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div style={{ fontSize: 20 }}>Folder name</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <input type="text" className="inputFolder"
                            value={name} onChange={e => setName(e.target.value)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div onClick={() => {
                        closeShow()
                        setName(null)
                    }} className="btn btn-default" >
                        Hủy
                    </div>
                    <button type="button" className="btn btn-primary" onClick={async () => {
                        await createFolder(name)
                        closeShow()
                        setName(null)
                    }}>
                        Tạo
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}