import firebase from 'firebase';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthenContext } from '../../../component/AuthenProvider';
import ModalDelete from '../../../utils/ModalDelete';

export default function RadioTaiLieu() {
    const [radios, setRadios] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false);
    const [filterAZ, setFilterAZ] = useState(false);
    const LIMIT = 50
    const [show, setShow] = useState(false);
    const [data, setData] = useState()
    const closeShow = () => setShow(false);
    const ALL_SUB_TAGS = ["2020", "2021", "2022", "2023", "2024", "2025"];
    const [subtag, setSubTag] = useState("");
    const algoliasearch = require('algoliasearch');
    const client = algoliasearch("ZKBCAY4L89", "6ba3a1ef548200f8ed1106443ca53053");
    const index = client.initIndex("Video");
    const [searchText, setSearchText] = useState("");
    let handleSubTags = (event, tag) => {
        if (event.target.checked) {
            setSubTag(tag)
        } else {
            setSubTag("")
        }
    }
    useEffect(() => {
        search(false)
    }, [subtag, filterAZ])
  
    const search = async (loadMore) => {
        setLoading(true)
        try {
            if (searchText.trim().length > 0) {
                let searchObj = {
                    filters: "tags:'Radio'",
                    hitsPerPage: LIMIT,
                    page: 0
                }

                if (subtag.trim().length > 0) {
                    searchObj.filters += ` AND subtags:'${subtag}'`
                }

                if (loadMore && radios.length > 0) {
                    searchObj.page = radios[radios.length - 1].page
                }

                let { hits } = await index.search(searchText, searchObj)

                let resp = []
                hits.forEach(doc => {
                    resp.push({ ...doc, id: doc.objectID, page: (searchObj.page + 1) })
                });

                if (loadMore) {
                    setRadios([...radios, ...resp])
                } else {
                    setRadios(resp)
                }
                setHasMore(resp.length === LIMIT)
            } else {
                let filter = firebase.firestore()
                    .collection("Video")
                    .where("tags", "array-contains-any", ["Radio"])
                if (filterAZ) {
                    filter = filter.orderBy("title", "asc");
                } else {
                    filter = filter.orderBy("createdDate", "desc");
                }
                if (subtag.trim().length > 0) {
                    filter = filter.where("subtags", "==", subtag.toString())
                }
                if (loadMore && radios.length > 0) {
                    filter = filter.startAfter(radios[radios.length - 1].createdDate)
                }

                filter = filter.limit(LIMIT);

                let querySnapshot = await filter.get()

                let resp = []
                querySnapshot.forEach(doc => {
                    resp.push({ ...doc.data(), id: doc.id })
                });

                if (loadMore) {
                    setRadios([...radios, ...resp])
                } else {
                    setRadios(resp)
                }
                setHasMore(resp.length === LIMIT)
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteVideo = async (doc) => {
        if (show) {
            try {
                await firebase.firestore().collection("Video").doc(doc.id).delete()
                search(false)
            } catch { }
        } else {
            setShow(true)
            setData(doc)
        }
    }
    let history = useHistory()
    return (
        <>
            <h1 className="h3 mb-2 text-gray-800">Radio</h1>
            <p className="mb-4">Radio</p>

            <div className="card shadow mb-4">
                <div className='subtags' >
                    {ALL_SUB_TAGS.map((tag, index) => {
                        return (
                            <div key={index}>
                                <input type='checkbox' name='subtags' onChange={(event) => handleSubTags(event, tag)}
                                    checked={subtag == tag ? true : false}
                                /> {tag}
                            </div>
                        )
                    })}
                </div>
                <div className="card-header py-3 wrap-header">
                    <div className="m-0 font-weight-bold text-primary float-left search__input" >
                        <input value={searchText} onChange={e => setSearchText(e.target.value)} placeholder='Tìm kiếm' />
                        <div style={{ cursor: 'pointer' }} onClick={() => search(false)} ><svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
                            <path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                        </svg></div>
                    </div>
                    <div className="m-0 font-weight-bold text-primary float-right"
                        onClick={() => {
                            history.push({
                                pathname: '/admin/tailieu/radio/add',
                                state: "Radio"
                            })
                        }}
                    >
                        + Thêm mới
                    </div>
                </div>
                <ModalDelete show={show} closeShow={closeShow} deleteItem={deleteVideo} data={data} />
                <div className='subtags' >
                    <div>
                        <input type='checkbox' name='filterAZ' onChange={() => setFilterAZ(prev => !prev)}
                            checked={!filterAZ}
                        /> 
                        {'  '}Ngày đăng
                    </div>
                    <div>
                        <input type='checkbox' name='filterAZ' onChange={() => setFilterAZ(prev => !prev)}
                            checked={filterAZ}
                        /> 
                        {'  '}A -{'>'} Z
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-hover" >
                            <thead>
                                <tr>
                                    <th >STT</th>
                                    <th >Tiêu đề</th>
                                    <th >Radio</th>
                                    <th >Subtag</th>
                                    <th >Ngày đăng</th>
                                    <th >Cài đặt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {radios?.map((item, index) => {
                                    return (
                                        <Item video={item} index={index + 1} deleteVideo={deleteVideo} key={item.id} />
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="5" > {hasMore && <a style={{ cursor: 'pointer', color: '#1c3f94' }} onClick={() => { search(true) }}> Load More....</a>}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

function Item({ video, index, deleteVideo }) {
    let userCtx = useContext(AuthenContext)
    let history = useHistory();

    return (
        <tr>
            <td className="verticalTd" >{index}</td>
            <td >
                {video?.title}
            </td>
            <td  >
                <a target="_blank" href={`https://youtu.be/${video.id}`}><img src={video?.thumbnailURL} className="sizeImage" /></a>
            </td>
            <td >
                {video?.subtags}
            </td>
            <td  >{typeof (video?.createdDate) == "object" ? video?.createdDate.toDate().toLocaleString('vi') : moment(video?.createdDate).format("HH:MM DD/MM/YYYY")}</td>

            <td className="btn__setting textAlign: 'center'">
                <button onClick={() => deleteVideo(video)} style={{ backgroundColor: 'transparent' }}>
                    <svg className="sizeSvg" viewBox="0 0 24 24" color="#65676b">
                        <path fill="currentColor" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
                    </svg>
                </button>
                <button
                    onClick={() => {
                        history.push({
                            pathname: '/admin/video/lienquan',
                            state: video
                        })
                    }} style={{ backgroundColor: 'transparent' }}
                >
                    <svg width="24px" height="24px" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg" aria-labelledby="plusIconTitle" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="#000000"> <title id="plusIconTitle">Plus</title> <path d="M20 12L4 12M12 4L12 20"/> </svg>
                </button>
                <button
                    onClick={() => {
                        history.push({
                            pathname: '/admin/tailieu/radio/edit',
                            state: video
                        })
                    }} style={{ backgroundColor: 'transparent' }}
                >
                    <svg className="sizeSvg" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z" />
                    </svg>
                </button>
            </td>
        </tr>
    )
}

