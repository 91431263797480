import firebase from 'firebase';
import React, { useState } from 'react';
import '../../../css/table.scss';
import ModalDelete from '../../../utils/ModalDelete';

export default function ImageTopic({ images, id }) {
    const deleteItem = (image) => {
        firebase.firestore()
            .collection("Topic")
            .doc(id)
            .update({
                images: firebase.firestore.FieldValue.arrayRemove(image)
            })
    }

    return (
        <div className="table-wrapper">
            <table className="fl-table">
                <thead>
                    <th>STT</th>
                    <th>Ảnh</th>
                    <th>Cài đặt</th>
                </thead>
                <tbody>
                    {images?.map((image, index) => {
                        return (
                            <Item key={index} index={index + 1} image={image} deleteItem={deleteItem} />
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

function Item({ image, index, deleteItem }) {
    const [show, setShow] = useState(false);
    const closeShow = () => setShow(false)
    return (
        <tr>
            <td>{index}</td>
            <td>
                <img src={image.url} className="sizeImage" alt="" />
            </td>
            <td>
                <button  onClick={() => {setShow(true)}} type="button" >
                    <svg className="sizeSvg" viewBox="0 0 24 24" color="#65676b">
                        <path fill="currentColor" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
                    </svg>
                </button>
            </td>
            <ModalDelete show={show} closeShow={closeShow} deleteItem={deleteItem} data={image} />

        </tr>
    )
}

