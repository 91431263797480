import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ModalDelete from '../../../utils/ModalDelete';

export default function Meeting() {
    const [meetings, setMeetings] = useState([])
    const [hasMore, setHasMore] = useState(false);
    const LIMIT = 50;
    const [show, setShow] = useState(false);
    const [data, setData] = useState()
    const history = useHistory();

    const closeShow = () => setShow(false)

    useEffect(() => {
        search(false)
    }, [])

    const search = async (loadMore) => {
        try {
            let filter = firebase.firestore()
                .collection("Meeting")
                .orderBy('createdDate', 'desc')
                .limit(LIMIT)

            if (loadMore && meetings.length > 0) {
                filter = filter.startAfter(meetings[meetings.length - 1].createdDate)
            }
            let querySnapshot = await filter.get()
            let resp = []
            querySnapshot.forEach(doc => {
                resp.push({ ...doc.data(), id: doc.id })
            });
            if (loadMore) {
                setMeetings([...meetings, ...resp])
            } else {
                setMeetings(resp)
            }
            setHasMore(resp.length === LIMIT)
        } catch (err) { console.log(err) }


    }

    const deleteMeeting = async (doc) => {
        if (show) {
            try {
                await firebase.firestore().collection("Meeting").doc(doc.id).delete()
                search(false)
            } catch { }
        } else {
            setShow(true)
            setData(doc)
        }
    }

    return (
        <>
            <h1 className="h3 mb-2 text-gray-800">Cuộc họp</h1>
            <div className="card shadow mb-4">
                <div className="card-header py-3 ">
                    <div className="m-0 font-weight-bold text-primary float-right" onClick={() => {
                            history.push({
                                pathname: '/admin/chungthien/meet/add',
                                state: "Meeting"
                            })
                        }}>
                        + Thêm mới
                    </div>
                </div>
                <ModalDelete show={show} closeShow={closeShow} deleteItem={deleteMeeting} data={data} />

                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-hover" >
                            <thead>
                                <tr>
                                    <th >STT</th>
                                    <th >ID</th>
                                    <th >Tiêu đề</th>
                                    <th >Password</th>
                                    <th >Hình ảnh</th>
                                    <th >Ngày đăng</th>
                                    <th >Cài đặt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {meetings?.map((item, index) => {
                                    return (
                                        <Item meeting={item} index={index + 1} deleteMeeting={deleteMeeting} key={item.id} />
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="5" > {hasMore && <a style={{ cursor: 'pointer', color: '#1c3f94' }} onClick={() => { search(true) }}> Load More....</a>}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

function Item({ meeting, index, deleteMeeting }) {
    let history = useHistory();

    return (
        <tr >
            <td className="verticalTd" >{index}</td>
            <td className="verticalTd" >{meeting?.ID}</td>
            <td className="verticalTd" >{meeting?.title}</td>
            <td className="verticalTd" >{meeting?.Password}</td>
            <td className="verticalTd" ><img alt="" src={meeting?.image?.url} height="30" /> </td>
            <td  >{meeting?.createdDate.toDate().toLocaleString('vi')}</td>
            <td className="btn__setting textAlign: 'center'">
                <button onClick={() => { deleteMeeting(meeting) }} style={{ backgroundColor: 'transparent' }}>
                    <svg className="sizeSvg" viewBox="0 0 24 24" color="#65676b">
                        <path fill="currentColor" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
                    </svg>
                </button>
                <button
                    onClick={() => {
                        history.push({
                            pathname: '/admin/chungthien/meet/edit',
                            state: meeting
                        })
                    }} style={{ backgroundColor: 'transparent' }}
                >
                    <svg className="sizeSvg" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z" />
                    </svg>
                </button>
            </td>
        </tr>
    )
}

