import firebase from 'firebase';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ModalDelete from '../../../utils/ModalDelete';


export default function VideoLienQuan() {
    let location = useLocation();
    const [video, setVideo] = useState();
    const [show, setShow] = useState(false);
    const [data, setData] = useState()
    const closeShow = () => setShow(false)

    useEffect(() => {
        firebase.firestore()
            .collection("Video")
            .doc(location.state.id)
            .onSnapshot(doc => {
                setVideo({...doc.data(), id: location.state.id});
            });
    }, [])


    const deleteVideo = async (doc) => {
        if (show) {
            try {
                const newVideosLienQuan = video.videosLienQuan.filter(item => item.uid !== doc.uid);
                let updateObj = {
                    videosLienQuan: newVideosLienQuan,
                };
                firebase.firestore()
                    .collection("Video")
                    .doc(video.id)
                    .update(updateObj)
            } catch (error) {
                console.log(error)
            }
        } else {
            setShow(true)
            setData(doc)
        }
    }
    let history = useHistory()
    return (
        <>
            <h1 className="h3 mb-2 text-gray-800">Video Liên Quan </h1>
            <p className="mb-4">Danh sách video liên quan {video?.tags[0]}</p>

            <div className="card shadow mb-4">
                <div className="card-header py-3 wrap-header">
                    <div className="m-0 font-weight-bold text-primary float-left search__input" >
                    </div>
                    <div className="m-0 font-weight-bold text-primary float-right"
                        onClick={() => {
                            history.push({
                                pathname: '/admin/video/lienquan/add',
                                state: video,
                            })
                        }}
                    >
                        + Thêm mới
                    </div>
                </div>
                <ModalDelete show={show} closeShow={closeShow} deleteItem={deleteVideo} data={data} />
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-hover" >
                            <thead>
                                <tr>
                                    <th >STT</th>
                                    <th >Tiêu đề</th>
                                    <th >Sub tags</th>
                                    <th >Video</th>
                                    <th >Ngày đăng</th>
                                    <th >Cài đặt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {video?.videosLienQuan?.map((item, index) => {
                                    return (
                                        <Item video={item} index={index + 1} deleteVideo={deleteVideo} key={item.id} />
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

function Item({ video, index, deleteVideo }) {
    return (
        <tr>
            <td className="verticalTd" >{index}</td>
            <td >
                {video?.title}
            </td>
            <td>{video?.subtags?.toString()}</td>
            <td  >
                <a target="_blank" href={`https://youtu.be/${video.id}`}><img src={video?.thumbnailURL} className="sizeImage" /></a>
            </td>   
            <td  >{typeof (video?.createdDate) == "object" ? video?.createdDate.toDate().toLocaleString('vi') : moment(video?.createdDate).format("HH:MM DD/MM/YYYY")}</td>

            <td className="btn__setting textAlign: 'center'">
                <button onClick={() => deleteVideo(video)} style={{ backgroundColor: 'transparent' }}>
                    <svg className="sizeSvg" viewBox="0 0 24 24" color="#65676b">
                        <path fill="currentColor" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
                    </svg>
                </button>
            </td>
        </tr>
    )
}