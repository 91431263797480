import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import firebase from 'firebase';
import { useHistory, useLocation } from 'react-router-dom';
import '../../../css/add.scss'
import { convertUrlSlug } from '../../../utils/RegexUrl';
import { resizeFile } from '../../../utils/FileUtils';

export default function EditKinhSach() {
    const { control, reset, handleSubmit, formState: { errors } } = useForm();
    let location = useLocation();
    const book = location.state;
    const [file, setFile] = useState()

    const handleToppic = (form) => {
        try {
            if (file) {
                uploadImageFile(file, form)
            } else {
                addTopic(form)
            }
        } catch (error) {
            console.log(error)
        }
    }
    let uploadImageFile = async (fileImage, form) => {
        let now = new Date()
        let time = `${now.getDate()}${now.getMonth()}${now.getFullYear()}${now.getHours()}${now.getMinutes()}`

        var storageRef = firebase.storage().ref();
        let fileNameExt = fileImage.name.substring(fileImage.name.lastIndexOf("."))
        const image = await resizeFile(fileImage, fileNameExt);

        var uploadTask = storageRef.child(`book/${book.tags.toString()}/${convertUrlSlug(form.name)}-${time}${fileNameExt}`).put(image);

        uploadTask.on('state_changed',
            (snapshot) => {
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED:
                        break;
                    case firebase.storage.TaskState.RUNNING:
                        break;
                }
            },
            (error) => {
                console.log("Error upload image" + error)
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    addTopic(form, downloadURL)
                });
            }
        );
    }

    let addTopic = async (form, imageUri) => {
        let user = firebase.auth().currentUser
        try {
            await firebase.firestore()
                .collection("Book")
                .doc(book.id)
                .update({
                    name: form?.name,
                    fileURL: form?.fileURL,
                    image: imageUri ? imageUri : book.image
                })
            history.goBack()
        } catch (error) {
            console.log(error)
        }
    }
    let history = useHistory()

    return (
        <div className="wrapper__add">
            <div className="wrapper__add--head">
                <label>Sửa PDF</label>
            </div>
            <div className="wrapper__add--content">
                <div className="wrapper__content">
                    <form>
                        <div className="form-group">
                            <label >Tên PDF</label>
                            <Controller
                                control={control}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <input
                                        onBlur={onBlur}
                                        onChange={e => onChange(e.target.value)}
                                        value={value}


                                        className="form-control"
                                    />
                                )}
                                name="name"
                                defaultValue={book?.name}
                                rules={{ required: true }}
                            />
                        </div>

                        <div className="form-group form-url">
                            <label >File PDF </label>
                            <Controller
                                control={control}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <input
                                        onBlur={onBlur}
                                        onChange={e => onChange(e.target.value)}
                                        value={value}


                                        className="form-control"
                                    />
                                )}
                                name="fileURL"
                                defaultValue={book?.fileURL}
                                rules={{ required: true }}
                            />

                        </div>
                        <label >Ảnh</label>
                        <div className="form-group">
                            <input type="file" accept="image/*"
                                onChange={e => {
                                    setFile(e.target.files[0])
                                }}
                            />
                        </div>
                        {file &&
                            <img id="target" src={URL.createObjectURL(file)} alt="" className="sizeImage" />
                        }
                        {!file &&
                            <img id="target" src={book?.image} alt="" className="sizeImage" />
                        }
                    </form>
                </div>
            </div>
            <div className="wrapper__add--button">
                <div className="wrap__button">

                    <button onClick={() => {
                        history.goBack()
                    }}>
                        Quay lại
                    </button>
                    <button onClick={handleSubmit(handleToppic)}>
                        Xác nhận
                    </button>
                </div>
            </div>
        </div>
    )
}






