import firebase from 'firebase';
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthenContext } from './AuthenProvider';

export default function Header() {
    let history = useHistory()
    let userCtx = useContext(AuthenContext)

    return <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
            <i className="fa fa-bars"></i>
        </button>
        <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown no-arrow">
                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">{userCtx.user?.displayName}</span>
                    <img className="img-profile rounded-circle"
                        src={userCtx.user?.photoURL} alt="" />
                </a>
            </li>
            <div className="topbar-divider d-none d-sm-block"></div>
            <li className="nav-item dropdown no-arrow mx-1">
                <a className="nav-link dropdown-item" href="#" onClick={() => {
                    firebase.auth().signOut().then(() => {
                        userCtx.setUser(null)
                        history.push("/login")
                    })
                }}>
                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    Logout
                </a>
            </li>
        </ul>
    </nav>
}