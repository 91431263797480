import firebase from 'firebase';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Controller, useForm } from "react-hook-form";
import { useHistory, useLocation } from 'react-router-dom';
import { AuthenContext } from '../../../component/AuthenProvider';
import '../../../css/add.scss';
import SongListsBook from './SongListsBook';

export default function DetailBook() {
    let location = useLocation();
    let userCtx = useContext(AuthenContext)

    const [data, setData] = useState();
    const book = location.state;
    useEffect(() => {
        search()
    }, [book?.id])
    useEffect(() => {
        search()
    }, [])
    const search = () => {
        firebase.firestore()
            .collection("Book")
            .doc(book?.id)
            .onSnapshot(doc => {
                setData({ ...doc.data(), id: doc.id })
            })
    }



    const [showAudios, setShowAudios] = useState(false);
    const closeShowAudios = () => {
        setShowAudios(false)
    }
    const [showSongs, setShowSongs] = useState(false);
    const closeShowSongs = () => {
        setShowSongs(false)
    }

    let history = useHistory()

    return (
        <div className="wrapper__add">
            <div className="wrapper__add--head">
                <label>Chi tiết PDF</label>
            </div>
            <form>
                <div className="form-group">
                    <label >Tên PDF</label>
                    <input className="form-control"
                        value={data?.name}
                        disabled
                    />

                </div>
                <div className="form-group form-url">
                    <label >File PDF <a> <svg className="sizeSvg" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z" />
                    </svg></a> </label>
                    <input className="form-control"
                        value={data?.fileURL}
                        disabled
                    />

                </div>
                <label >Ảnh</label>
                <div className="form-group">
                    <img id="target" src={book?.image} alt="" className="sizeImage" />
                </div>
                <label >Tags</label>

                <div className="form-group"  >
                    <div >
                        {data?.tags?.toString()}
                    </div>


                </div>


                <div className="wrapper__content--item" style={{ width: '100%', justifyContent: 'space-between' }}>
                    <label>Phụ lục:</label>
                    <div className="wrapper__content--item--add" >
                        <div onClick={() => setShowSongs(true)}>
                            + Thêm mới
                        </div>
                    </div>
                </div>
                {data?.songList?.length > 0 &&
                    <SongListsBook songList={data?.songList} id={data?.id} search={search} />
                }
                <AddSongLists show={showSongs} closeShow={closeShowSongs} id={book?.id} search={search} />
                <div className="wrapper__add--button">
                    <button type="button" onClick={() => history.goBack()} className="btn btn-primary">Quay lại</button>
                </div>
            </form>
        </div>
    )
}
function AddSongLists({ show, closeShow, id }) {
    const { control, reset, handleSubmit, formState: { errors } } = useForm();
    let sumbitAdd = (form) => {
        firebase.firestore()
            .collection("Book")
            .doc(id)
            .update({
                songList: firebase.firestore.FieldValue.arrayUnion({ title: form.title, pageIdx: parseInt(form.pageIdx) })
            }).then(() => {
                closeShow()
            }).catch(err => {
                console.log(err)
            })
    }
    return (
        <>
            <Modal
                show={show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={closeShow}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Thêm phụ lục PDF
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label >Tiêu đề</label>
                            <Controller
                                control={control}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <input
                                        onBlur={onBlur}
                                        onChange={e => onChange(e.target.value)}
                                        value={value}
                                        className="form-control" placeholder="Tiêu đề..."
                                    />
                                )}
                                name="title"
                                defaultValue=""
                                rules={{ required: true }}
                            />
                        </div>
                        <div className="form-group">
                            <label >Trang Số</label>
                            <Controller
                                control={control}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <input
                                        onBlur={onBlur}
                                        type="number"
                                        onChange={e => onChange(e.target.value)}
                                        value={value}
                                        className="form-control" placeholder="Trang Số..."
                                    />
                                )}
                                name="pageIdx"
                                defaultValue=""
                                rules={{ required: true }}
                            />

                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeShow}>
                        Đóng
                    </Button>
                    <button type="submit" className="btn btn-primary" onClick={handleSubmit(sumbitAdd)}>
                        Xác nhận
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}