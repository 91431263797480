import firebase from 'firebase';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import '../../../css/add.scss';

export default function EditLuuTru() {
    let location = useLocation();
    const video = location.state;
    const ALL_SUB_TAGS = ["Lưu trữ 1", "Lưu trữ 2", "Lưu trữ 3", "Lưu trữ 4", "Lưu trữ 5", "Lưu trữ 6"];
    const [subtags, setSubTags] = useState(video?.subtags);
   

    let history = useHistory();

    let addForm = async () => {
        try {
            let updateObj = {
                subtags: subtags.length == 0 ? ALL_SUB_TAGS : subtags
            }
            await firebase.firestore()
                .collection("Video")
                .doc(video.id)
                .update(updateObj)
            history.goBack()
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="wrapper__add">
            <div className="wrapper__add--head">
                <label>Sửa Lưu trữ</label>
            </div>
            <div className="wrapper__add--content">
                <div className="wrapper__content">
                    <form>
                        <div className="form-group">
                            <label >Tiêu đề </label>
                            <input
                                value={video.title}
                                className="form-control"
                                disabled
                            />
                        </div>
                    </form>
                    <div className="wrapper__content--item">
                        <label>Sub Tags:</label>
                        <div className="wrapper__content--item--tags">
                            {ALL_SUB_TAGS.map((tag, index) => {
                                return (
                                    <div style={{ alignItems: 'center', flex: 1, flexDirection: 'row', display: 'flex' }} key={index}>
                                        <input
                                            type="radio"
                                            name="subtags"
                                            value={tag}
                                            onClick={() => setSubTags(tag)}
                                            checked={subtags == tag ? true : false}
                                        />
                                        <div style={{ color: "#333" }}>&nbsp; {tag}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper__add--button">
                <div className="wrap__button">
                    <button onClick={() => {
                        history.goBack()
                    }}>
                        Quay lại
                    </button>
                    <button onClick={addForm}>
                        Xác nhận
                    </button>
                </div>
            </div>
        </div>
    )
}

